.efz-sub-title {
    h5 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--primary-color);
        margin-bottom: 0.5rem;
    }
    hr {
        margin-top: 0;
        margin-bottom: 2rem;
    }
}