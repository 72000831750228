@use "../global/_global-dir" as *;

.efz-title {
    h1 {
        font-weight: 600;
        margin: 0 0 0.5rem;
        font-size: 1rem;
        color: $neutral-800;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    hr {
        margin: 0;
        width: 70px;
        height: 3px;
        background-color: var(--primary-color);
        border: none;
        border-radius: 5px;
        margin-bottom: 1.5rem;
        opacity: 1;
    }
}
