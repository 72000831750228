.selected-checkbox-icon {
    rect {
        &:last-of-type {
            stroke: var(--primary-color);
        }
    }

    path {
        fill: var(--primary-color);
        stroke: var(--primary-color);
    }

}