@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

.process-details-v2 {
    display: flex;
    &-view {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem 1.5rem;
        border: 1px solid $border-color;
        border-radius: 5px;
        background-color: white;
        margin-bottom: 1.5rem;

        .asset-view-dropdown {
            cursor: pointer;
            position: absolute;
            background: #ffffff;
            border-width: 0px 1px 1px 1px;
            border-style: solid;
            border-color: #c4c4c4;
            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
            border-radius: 0px 0px 5px 5px;
            padding: 1.5rem 2rem;
            top: 70px;
            left: -1px;
            z-index: 9;

            .icon {
                &-board {
                    path {
                        stroke: var(--primary-color);
                    }
                }
                &-maintenance {
                    path {
                        fill: var(--primary-color);
                    }
                }
            }
        }

        .left-side {
            &.asset {
                cursor: pointer;
                svg {
                    path {
                        stroke: none;
                        fill: var(--primary-color);
                    }
                }
            }
            display: flex;
            column-gap: 1rem;
            align-items: center;
            span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: $font-weight-normal;
                font-size: 1rem;
                line-height: 1.5rem;
                color: var(--primary-color);
            }
            svg {
                path {
                    stroke: var(--primary-color);
                }
            }
        }
        .right-side {
            display: flex;
            .disabled {
                pointer-events: all;
                cursor: not-allowed;
                color: $neutral-400;
                background-color: $neutral-100 !important;
                svg {
                    path {
                        fill: $neutral-400 !important;
                    }
                }
                
            }
            .MuiButton-root {
                border: 1px solid;
                background-color: var(--primary-color-bleached);
                svg {
                    path {
                        fill: var(--primary-color);
                    }
                }
            }
        }
        .dropdown-options {
            position: absolute;
            border: 1px solid $border-color;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            top: 4rem;
            right: 1.5rem;
            background-color: white;

            .download-docs-button {
                padding: 0.75rem 1.875rem;
            }

            .disabled {
                svg {
                    path {
                        fill: rgba(0, 0, 0, 0.26) !important;
                    }
                }
            }

            .MuiButton-text {
                text-transform: none;

                svg {
                    path {
                        fill: var(--primary-color);
                    }
                }
            }
        }
    }
    &-section {
        width: 100%;
        display: flex;
        flex-direction: column;

        &:first-of-type {
            min-width: calc((565 / 1200) * 100%);
        }

        &-informations {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 1rem;

            .process-details-information {

                &-edit-btn {
                    border: 1px solid var(--primary-color);
                    width: 28px;
                    padding: 12px 0;
                }

                .edit-information {
                    background-color: var(--primary-color-bleached);
                    border: 1px solid var(--primary-color);
                    border-radius: 0.5rem;
                    padding: 0.875rem 1rem;
                    margin-bottom: 1rem;

                    &-red {
                        @extend .edit-information;
                        background-color: #FFF3F1;
                        border: 1px solid #EB7973;
                    }
                    
                    &-white {
                        @extend .edit-information;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 0.5rem;
                        background-color: white;
                        border: 1px solid #C7C7C7;

                        .edit-information-body {
                            margin-bottom: 0;
                        }
                    }

                    &-header {
                        display: flex;
                        align-items: center;
                        column-gap: 0.5rem;
                        color: var(--primary-color);
                        font-size: 0.875rem;
                        font-weight: 500;
                        margin-bottom: 0.5rem;

                        svg {
                            path {
                                fill: var(--primary-color);
                            }
                        }
                    }

                    &-header.red {
                        color: #D0433C;
                        svg{
                            path {
                                fill: #D0433C;
                            }
                        }
                    }

                    &-body {
                        margin-bottom: 1rem;
                        font-size: 12px;
                        line-height: 20px;
                        color: #202020;

                    }

                    &-footer {
                        display: flex;
                        column-gap: 1.625rem;

                        .MuiButtonBase-root {
                            background-color: white;
                        }
                    }
                }

                .switch-uncheck {
                    .MuiSwitch-track {
                        background: #f1c7c5;
                        border: 1px solid #e8a19e;
                        border-radius: 12px;
                    }
                }
            }
        }
        .facility-information {
            .two-column-grid {
                .map_view {
                    grid-column: span 2;
                }
            }

            .MuiPaper-root {
                .address-container {
                    margin-top: 1.5rem;
                    border-radius: 5px;
                }
            }
        }
        .facility-information {
            .two-column-grid {
                .map_view {
                    grid-column: span 2;
                }
            }

            .MuiPaper-root {
                .address-container {
                    margin-top: 1.5rem;
                    border-radius: 5px;
                }
            }
        }
    }

    .two-column-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 0.75rem;
        .operational {
            grid-column: span 2;
            flex-flow: row wrap;
            align-items: center;
            margin-left: -9px;
            margin-top: -9px;
        }
    }

    .input-container {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        line-height: 1.1875rem;

        &-button {
            text-transform: capitalize;
            border: 0.0625rem solid var(--primary-color);
            font-family: Poppins;
            font-size: 0.75rem;
            padding: 0.375rem 0.75rem;
            &-text {
                padding: 0.125rem 0;
                color: var(--primary-color);
                height: 100%;
                text-transform: lowercase;;
                text-align: center;
                font-size: 0.875rem;
                text-decoration-line: underline;
            }
        }

        .input-error {
            margin-top: 0.25rem;
            color: $error-600;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;

            svg {
                path {
                    fill: $error-600;
                }
            }
        }

        label {
            margin-bottom: 0.2rem;
            font-weight: $font-weight-semibold;
            color: $silver-chalice;
            font-size: 0.875rem;
            text-transform: uppercase;

            @media (max-height: 767.98px) {
                margin-bottom: 0;
            }
        }

        @include bootstrap.media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 0.5rem;
            margin-bottom: 1rem;
        }

        &-dropdown,
        &-textfield {
            width: 80%;
            margin-right: 1rem;
        }
    }

    .table {
        margin-bottom: 0;

        .header,
        .body .row-item {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            & > *:first-child {
                grid-column: 1;
                margin-right: 0.5rem;
            }

            & > *:nth-child(2) {
                grid-column: 2 / span 3;
                margin-left: 0.5rem;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                column-gap: 1.5rem;

                span {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .header {
            margin-bottom: 1rem;

            &-value {
                span {
                    padding: 0.5rem;
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 1.1875rem;
                    text-align: center;
                    background-color: #eaeced;
                    border-radius: 5px;

                    &:last-child:not(:first-child) {
                        background-color: var(--primary-color-bleached);
                        color: var(--primary-color);
                    }
                }
            }
        }

        .body {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 0.25rem;

            .row-item {
                &-header {
                    margin-bottom: 0.25rem;
                    padding: 0.375rem 0.5rem;
                    font-size: 0.75rem;
                    font-weight: 600;
                    line-height: 1rem;
                    text-align: center;
                    background-color: #eaeced;
                    border-radius: 5px;
                    font-family: "Open Sans";
                    font-style: normal;
                    color: $steel-600;
                }

                &-value {
                    border-bottom: 1px solid #e5e5e5;

                    span {
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.1875rem;
                        color: $steel-600;

                        &.bold {
                            font-weight: 500;
                            color: $neutral-800;
                        }
                    }
                }

                &:last-child {
                    .row-item-value {
                        border-bottom-color: transparent;
                    }
                }
            }

            .row-headers,
            .row-data {
                display: flex;
                flex-flow: column nowrap;
                row-gap: 0.5rem;
            }

            .row-headers {
                grid-column: 1;
                margin-right: 0.5rem;

                &-item {
                    width: 100%;
                    padding: 0.375rem 0.5rem;
                    font-size: 0.75rem;
                    font-weight: 600;
                    line-height: 1rem;
                    text-align: center;
                    background-color: #eaeced;
                    border-radius: 5px;
                }
            }

            .row-data {
                grid-column: 2 / span 3;
                margin-left: 0.5rem;

                &-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    border-bottom: 1px solid #e5e5e5;
                }
            }
        }
    }

    .equipment-group {
        margin-top: 0.5rem;
        padding: 0;
        border: none;

        .MuiAccordionSummary-root {
            flex-direction: row-reverse;
            column-gap: 1rem;
            background-color: #f5f5f5;
            border-radius: 3px;
            margin-bottom: 0.75rem;
            min-height: unset;

            .MuiAccordionSummary-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $neutral-800;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 19px;

                .technical-details-edit-btn {
                    background-color: white;
                    box-shadow: 0 2px 2px 1px #00000014;
                    border: 1px solid var(--primary-color);
                    width: 25px;
                    height: 25px;
                    padding: 3px;
                }
            }
        }
    }
}

#asset-management-tooltip {
    background-color: var(--paper-color);
    .tooltip-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start ;
        ul {
            padding-left: 1.25rem;
            margin-bottom: 0;
            li {
                text-align: left;
            }
        }
    }
    .MuiTooltip-tooltip {
        background-color: var(--primary-color-bleached);
        border-color: var(--primary-color);
        padding: 0.8125rem 0.9375rem;
        .MuiTooltip-arrow {
            background-color: var(--paper-color);
        }
        .MuiTooltip-arrow::before {
            background-color: var(--primary-color-bleached);
            border-color: var(--primary-color);
        }
    }
}
