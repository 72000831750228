@use "../global/_global-dir" as *;

.create-process-button,
.create-asset-management-button {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);

    .button-text {
        margin-left: 1rem;
    }

    svg {
        width: 14px !important;
        path {
            fill: var(--primary-color);
        }
    }

    &:hover {
        border: 1px solid var(--primary-color);
    }
}

.save-process-button {
    background-color: var(--primary-color);

    &:hover {
        background-color: var(--primary-color-light);
    }
}

.processes-tabs {
    flex-wrap: nowrap;
    background-color: inherit !important;
    box-shadow: inherit !important;
    margin-bottom: 1.625rem;
    max-height: 44px;

    &-unselected {
        border-bottom: 2px solid $neutral-200;
        background-color: $neutral-100;
        color: #000000;
        box-sizing: border-box;
    }

    &-selected {
        color: var(--primary-color) !important;
        background-color: var(--primary-color-bleached);
        box-sizing: border-box;
        border-bottom: 2px solid var(--primary-color);
    }

    .MuiTabs-flexContainer {
        justify-content: space-between;

        button {
            font-size: 0.875rem;
            text-transform: none !important;
        }
    }

    .MuiTabs-indicator {
        // make the red defaul bar invisible
        display: none !important;
        // background-color: inherit !important;
    }

    // svg {
    //     path {
    //         fill: var(--primary-color);
    //     }
    // }
}

.processes-filters {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 1rem;

   

    &-dropdown {
        border-top: none;
        border-bottom: 1px solid var(--divider-color);
        margin-bottom: 0;

        .submit-button-container {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;

            .MuiButtonBase-root {
                border: 1px solid;
            }
        }

        label {
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
            margin-bottom: 5px;
        }

        .date-inputs {
            column-gap: 0.625rem;
            flex: 1;
        }

        .MuiInputAdornment-root {
            font-size: 0.875rem;
            color: var(--disabled-color);
            margin-right: 0;

            & ~ .MuiOutlinedInput-input {
                padding-right: 1rem !important;
            }
        }
    }
}
