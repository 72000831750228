.processes-filters {
    margin: 1.5rem 0;
    .global-filter-input {
        width: 45%;
    }
    .processes-filters-btns {
        display: flex;
        width: 55%;
        align-items: center;
        margin-left: 1rem;
        .filters-bar-button {
            svg {
                path {
                    fill: var(--primary-color)
                }
            }
        }

    }
}