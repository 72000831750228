@use "../global/_global-dir" as *;

.generic-group-accordions{
        padding-top: 0.625rem;

        .MuiAccordion-root {
            // margin-bottom: 0.8rem;
            background-color: #f5f5f5;
            box-shadow: none !important;
            border-radius: 3px;
            outline: none!important;
            margin-bottom: 0.625rem;
            &::before{
                display: none;
            }
            &.Mui-expanded {
                margin-bottom: 0.375rem;
                min-height: 35px;
            }
        }

        .MuiAccordionSummary-expandIcon.Mui-expanded {
            transform: rotate(90deg);
        }

        .MuiCollapse-root {
            background-color: var(--paper-color);
        }

        .MuiAccordionSummary-root {
            flex-direction: row-reverse;
            height: 40px;
            display: flex;
            border-radius: 3px;
            padding: 0;
            &.Mui-expanded{
                min-height: 48px;
            }
            .MuiAccordionSummary-expandIconWrapper {
                padding: 1rem;
            }

            .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                transform: rotate(90deg);
            }
        }

        .MuiAccordionSummary-content {
            margin-left: 0;
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
            line-height: 19px;
        }

        .site-survey-input-upload label{
            color: $silver-chalice!important;
        }

}