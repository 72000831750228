@use "../global/_global-dir" as *;

.selectable-element {
    &.checked {
        background: var(--primary-color-bleached);
        border: 1.5px solid var(--primary-color);
        border-radius: 5px;
        margin: 0.75rem 1rem 1rem 0;
        padding: 0.6rem 2rem;
        pointer-events: none;
    }
    &.unchecked {
        background: #ffffff;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        margin: 0.75rem 1rem 1rem 0;
        padding: 0.6rem 2rem;
        cursor: pointer;
    }
    &-with-image {
        margin: 1rem 2rem 0 0;
        // height: 68px;
        // width: 70px;

        img {
            width: 70px;
            height: 68px;
            max-width: none !important;
            max-height: none !important;
            border-radius: 10px;
        }

        .selectable-label {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            display: flex;
            justify-content: center;
            margin-bottom: 0.5rem;
        }
        &.checked {
            pointer-events: none;
            .selectable-label {
                color: var(--primary-color);
                font-weight: 600;
            }
        }
        &.unchecked {
            cursor: pointer;
            .selectable-label {
                color: $neutral-800;
            }
        }
    }
}
