@use "../global/_global-dir" as *;

.timeline-section {
    min-width: 30%;
    // margin: 2rem 1.5rem;

    @media (max-height: 767.98px) {
        // min-width: 25vw;
        // max-width: 25vw;
    }

    .timeline-section-body {
        display: flex;
        flex: row nowrap;
        margin-bottom: 1rem;
        font-size: 0.875rem;
    }

    .active {
        border: 1px solid var(--primary-color);
        border-radius: 10px;
        padding: 1rem;
        background-color: var(--primary-color-bleached);

        .timeline-bullet .timeline-dashes {
            border-color: var(--primary-color) !important;
            height: calc(100% - 3em);
        }

        .timeline-content {
            h1 {
                color: var(--primary-color);
                color: var(--primary-color);
            }

            h2 {
                color: var(--primary-color);
                margin: 0 0 0.375rem;
            }

            h3 {
                color: var(--primary-color);
                font-size: 0.625rem;
                text-transform: uppercase;
                margin: 0 0 0.25rem;
            }

            .state-date {
                color: black;
                margin-bottom: 0.375rem;

                &.v2 {
                    margin-bottom: 1.125rem;
                }
            }

            .state-reason {
                color: black;

                .bold {
                    font-weight: $font-weight-semibold;
                }
            }

            .comment-section {
                color: black;
            }
        }

        .pending-icon {
            path {
                &:first-of-type,
                &:last-of-type {
                    stroke: var(--primary-color);
                }

                &:nth-child(2) {
                    fill: var(--primary-color);
                }
            }
        }

        .ss-requested-icon {
            path {
                &:first-child {
                    stroke: var(--primary-color);
                }

                &:nth-child(2),
                &:last-of-type {
                    stroke: var(--primary-color);
                    fill: var(--primary-color);
                }
            }
        }

        .ss-scheduled-icon {
            path {
                &:first-child {
                    stroke: var(--primary-color);
                }

                &:not(:first-child) {
                    fill: var(--primary-color);
                }
            }

            rect {
                stroke: var(--primary-color);
            }
        }

        .validated-icon {
            path {
                &:first-child {
                    stroke: var(--primary-color);
                }

                &:last-child {
                    fill: var(--primary-color);
                }
            }
        }

        .reformulate-icon {
            path {
                &:not(:nth-child(2)),
                &:not(:nth-child(3)) {
                    stroke: var(--primary-color);
                }

                &:nth-child(2),
                &:nth-child(3) {
                    fill: var(--primary-color);
                }
            }
        }

        .paused-icon,
        .error-icon {
            path {
                stroke: var(--primary-color);
            }
        }

        .ss-executed-icon,
        .pricing-completed-icon {
            path,
            line {
                stroke: var(--primary-color);
            }

            path {
                &:not(:first-child) {
                    fill: var(--primary-color);
                }
            }
        }
    }

    .not-active {
        padding: 0 1rem;

        .timeline-content {
            row-gap: 0.375rem;
        }

        // ICONS Colors for not-active states
        .pending-icon {
            path {
                &:first-of-type,
                &:last-of-type {
                    stroke: $steel-600;
                }

                &:nth-child(2) {
                    fill: $steel-600;
                }
            }
        }

        .ss-requested-icon {
            path {
                &:first-child {
                    stroke: $steel-600;
                }

                &:nth-child(2),
                &:last-of-type {
                    stroke: $steel-600;
                    fill: $steel-600;
                }
            }
        }

        .ss-scheduled-icon {
            path {
                &:first-child {
                    stroke: $steel-600;
                }

                &:not(:first-child) {
                    fill: $steel-600;
                }
            }

            rect {
                stroke: $steel-600;
            }
        }

        .validated-icon {
            path {
                &:first-child {
                    stroke: $steel-600;
                }

                &:last-child {
                    fill: $steel-600;
                }
            }
        }

        .reformulate-icon {
            path {
                &:not(:nth-child(2)),
                &:not(:nth-child(3)) {
                    stroke: $steel-600;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    fill: $steel-600;
                }
            }
        }

        .paused-icon,
        .error-icon {
            path {
                stroke: $steel-600;
            }
        }

        .ss-executed-icon,
        .pricing-completed-icon {
            path,
            line {
                stroke: $steel-600;
            }

            path {
                &:not(:first-child) {
                    fill: $steel-600;
                }
            }
        }
    }

    .grayscale {
        filter: grayscale(1);
    }

    .timeline-bullet {
        min-width: 33px;
        margin-right: 1rem;

        .timeline-dashes {
            height: calc(100% - 3em);
            border-left: 1px dashed $silver-chalice;
            margin: 0.5rem 1rem;
        }
    }

    .timeline-content {
        margin-bottom: 2rem;

        h1 {
            color: #49555f;
            font-size: 1rem;
            margin-bottom: 0.25rem;
        }

        h2 {
            margin-bottom: 0.25rem;
            font-size: 1rem;
            color: $steel-700;
        }

        .comment-section {
            margin-top: 0.5rem;
            margin-bottom: 0.375rem; // 6px -> 4px já vem do margin-top dos links
            color: $steel-700;
            font-size: 0.75rem;
            p {
                line-break: anywhere;
                line-height: 1rem;
                margin-bottom: 0 !important;
                min-height: 1rem;
            }
        }

        .state-comment,
        .state-date,
        .state-reason {
            font-size: 0.75rem;
            color: $steel-700;
        }

        .state-comment {
            span:first-child {
                font-weight: 600;
            }
        }

        .state-comment:last-of-type:not(:last-child) {
            margin-bottom: 1.125rem;
        }

        .timeline-active-state {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 0.625rem;
            text-transform: uppercase;
            font-weight: $font-weight-semibold;

            & > div {
                margin: 0 !important;
            }
        }

        .link {
            cursor: pointer;
            &-disabled {
                color: $steel-700;
                pointer-events: none;
            }
        }

        .link:hover {
            color: var(--primary-color);
        }

        .underline {
            text-decoration: underline;
        }

        .disabled {
            &-icon {
                path {
                    fill: $steel-600;
                }
            }
        }
    }
    .timeline-link {
        display: flex;
        align-items: center;
        .loading-component {
            flex: 0 !important;
            min-width: unset !important;
            margin-right: 0.5rem;
        }
        .disabled {
            color: $steel-700;
            pointer-events: none;
        }
    }

    .link-disabled {
        svg {
            path {
                fill: $steel-600 !important;
            }
        }
    }
}

.tir-section {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin: 0.5rem 0;
    font-weight: $font-weight-semibold;
    font-size: 0.75rem;

    .tir-icon {
        &-red {
            circle {
                fill: #ee162d;
            }
        }
        &-yellow {
            circle {
                fill: #e7ac26;
            }
        }
        &-green {
            circle {
                fill: #3ab694;
            }
        }
    }

    .tir-additional-cost {
        text-transform: none !important;
        margin-top: 0.5rem;
    }

    .tir-percent {
        &-red {
            color: #ee162d;
        }
        &-yellow {
            color: #e7ac26;
        }
        &-green {
            color: #3ab694;
        }
    }

    .loading-tir-text {
        color: var(--primary-color);
    }

    .loading-tir-icon {
        .MuiCircularProgress-colorPrimary {
            color: var(--primary-color);
        }
    }

    .money-bag-tir {
        &-red {
            path {
                fill: #ee162d;
                stroke: #ee162d;
            }
        }
        &-yellow {
            path {
                fill: #e7ac26;
                stroke: #e7ac26;
            }
        }
        &-green {
            path {
                fill: #3ab694;
                stroke: #3ab694;
            }
        }
    }
}

.tir-additional-cost {
    &-red {
        color: #ee162d;
        @extend .tir-additional-cost;
    }
    &-yellow {
        color: #e7ac26;
        @extend .tir-additional-cost;
    }
    &-green {
        color: #3ab694;
        @extend .tir-additional-cost;
    }
}
