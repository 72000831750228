@use "../global/_global-dir" as *;

.processes-container {
    .top-search-bar {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 1rem;
        padding: 0.5rem 15px;
        height: 55px;
        justify-content: space-between;

        .filters-btns{
            align-items: center;
        }

        .filters-bar-button {
            &.cross-button {
                svg {
                    path {
                        fill: var(--primary-color);
                    }
                }
            }

            span {
                font-size: 0.9rem;
                white-space: nowrap;
                min-width: initial;
            }

            &:hover {
                color: var(--primary-color);
            }
        }

        #outlined-processes-search {
            height: 19px;
            padding: 8px 12px;
            min-width: 150px;
            width: 450px;
        }

        .loading-component {
            min-width: 0;
        }

        .MuiTypography-root {
            white-space: nowrap;
        }

        .MuiInputBase-root {
            background-color: var(--paper-color);
        }

        .update-table-button {
            // @extend .toggle-filters-button;
            // position: absolute;
            // right: 0;
            height: unset;
            padding: 0;
            background-color: transparent;
            .MuiButton-text {
                height: unset;
                padding: 0px;
            }

            &:hover {
                background-color: transparent;
                svg {
                    path {
                        stroke: var(--primary-color);
                    }

                    path:nth-of-type(2) {
                        fill: var(--primary-color);
                    }

                    path:nth-of-type(3) {
                        fill: var(--primary-color);
                    }
                }
            }

            &-disabled {
                @extend .update-table-button;
                pointer-events: none;
                svg {
                    path {
                        stroke: var(--disabled-color);
                    }

                    path:nth-of-type(2) {
                        fill: var(--disabled-color);
                    }

                    path:nth-of-type(3) {
                        fill: var(--disabled-color);
                    }
                }
            }
        }
    }
}

.processes-filters-dropdown {
    border-top: none;
    border-bottom: 1px solid var(--divider-color);
    margin-bottom: 1rem;

    .submit-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    label {
        font-size: 0.875rem;
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    .date-inputs {
        column-gap: 0.625rem;
        flex: 1;
    }

    .MuiInputAdornment-root {
        font-size: 0.875rem;
        color: var(--disabled-color);
        margin-right: 0;

        & ~ .MuiOutlinedInput-input {
            padding-right: 1rem !important;
        }
    }
}

.MuiInputBase-input {
    // Placeholders for the filters inputs
    &::placeholder {
        font-size: 14px;
    }
}
