@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

.content-header {
    @include display-flex(flex, row, nowrap);
    background-color: var(--background-color);
    color: var(--text-color);
    position: sticky;
    top: 0;
    z-index: 1050;
    align-items: center;
    justify-content: center;
    // max-height: 55px;
    // height: 55px;

    .content-header-container {
        @include display-flex(flex, row, nowrap);
        flex: 1 1 0;
        padding: 8px 45px 7px 45px;
        max-width: 1300px;

        @include bootstrap.media-breakpoint-down(md) {
            padding: 8px 10px 7px 10px;
        }
    }

    .content-header-content {
        @include display-flex(flex, row, nowrap);
        margin-bottom: 0px;
        align-items: center;
        justify-content: center;
        flex: 2 1 100%;
    }

    // Title of the page
    .page-info {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include bootstrap.media-breakpoint-down(md) {
            flex-flow: column nowrap;
        }

        h3 {
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: $font-weight-semibold;
        }
    }

    .back-button-container {
        display: flex;
        flex: 1 1 25%;
        align-items: center;
        justify-content: flex-start;
    }

    .back-button {
        @include display-flex(flex, row, nowrap);
        text-transform: uppercase;
        padding: 0rem 1rem;
        height: 40px;
        font-size: 0.875rem;

        > span {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            text-align: left;
            line-height: 1rem;
        }

        & i {
            font-size: 1.875rem;
            font-weight: 200;
            width: 1.875rem;
            text-align: center;
            line-height: 1rem;
        }

        &:hover {
            color: var(--primary-color);
        }

        @include bootstrap.media-breakpoint-down(lg) {
            .button-text {
                display: none;
            }
        }
    }

    .forward-button-container {
        display: flex;
        flex: 1 1 25%;
        align-items: center;
        justify-content: flex-end;
        max-width: 25%;
    }

    .forward-button {
        @include display-flex(flex, row, nowrap);
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        height: 40px;

        > span {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        & i {
            font-size: 1.875rem;
            font-weight: 200;
            width: 1rem;
            text-align: center;
            line-height: 1rem;
            margin-left: 0.5rem;
        }

        @include bootstrap.media-breakpoint-down(lg) {
            padding: 0.4rem 0.7rem;
        }

        @include bootstrap.media-breakpoint-down(md) {
            i {
                margin-left: 0;
            }

            .button-text {
                display: none;
            }
        }
    }

    .edit-proposal-info-box {
        display: flex;
        align-self: center;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        z-index: 2;
        margin-left: 2rem;
        flex-flow: column;
        min-width: 33%;
        transition: min-width 0.5s !important;

        &.collapsed {
            min-width: 20% !important;
            transition: min-width 0.5s !important;
        }

        &-title {
            background-color: var(--primary-color);
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                cursor: pointer;
            }

            i {
                font-size: large;
                font-weight: bold;
            }
        }

        &-body {
            display: flex;
            flex-flow: row;

            & > div {
                display: flex;
                flex-flow: column;
                align-items: center;
                margin: 5px 10px;
                flex: 1 1 0;

                &:nth-child(2) {
                    border-left: 1px solid $gray-default;
                    border-right: 1px solid $gray-default;
                    margin: 5px 0;
                    padding: 0 10px;
                }

                & div {
                    display: flex;
                    flex-wrap: nowrap;
                    white-space: nowrap;

                    &:first-child {
                        font-weight: bold;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }

    .increase-power-info-box {
        display: flex;
        align-self: center;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        z-index: 2;
        margin-left: 2rem;
        flex-flow: column;

        .increase-power-info-box-title {
            background-color: var(--primary-color);
            color: bootstrap.$white;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: $font-weight-semibold;

            i {
                font-size: large;
                font-weight: bold;
            }
        }
    }
}
