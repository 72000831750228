@use "../global/_global-dir" as *;

.react-table-loading {
    & > tbody {
        background-color: rgba(0, 0, 0, 0.1) !important;
        pointer-events: none;
    }
}

.react-table-hover tbody tr:hover {
    background-color: rgba($black, 0.05);
}
