@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

.dashboard-top-nav {
    display: flex;

    .efz-back-button {
        margin: 0;
        padding: 0;
    }
}
.extended-dialog {
    h2 {
        margin: 0;
        color: var(--primary-color) !important;
    }
}
.filter-section {
    .multi-autocomplete {
        width: max-content;
        min-width: 232px;
        label {
            font-family: Open Sans;
            font-weight: 600;
            text-align: left;
            color: #0D0D0D;
        }
    }
}
.dashboard {
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--paper-color);
    border-radius: $border-radius-lg;
    box-shadow: $shadow-xl;
    padding: 2.5rem 4.625rem 1.5rem 4.625rem;
    margin-bottom: 2rem;

    &.v2 {
        background-color: none;
        border-radius: unset;
        box-shadow: unset;
        padding: 0;
        margin-bottom: 0;
    }

    .general-section,
    .schedule-section,
    .states-section,
    .filter-section {
        margin-bottom: 2.5rem;

        hr {
            margin: 0;
            width: 70px;
            height: 3px;
            background: var(--primary-color);
            margin-bottom: 1.75rem;
            opacity: 1;
            border-top-color: rgba(0, 0, 0, 0.1);
        }

        .section-body {
            width: 60%;

            @media (max-height: 767.98px) {
                width: 50%;
            }
        }
    }

    .general-section .section-body{
        display: flex;
        flex-flow: row;

        > div {
            display: flex;
            flex-flow: row nowrap;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 5px;
            margin-right: 1.5rem;
            padding: 1.5rem;
            width: 23vw;
            justify-content: space-between;
        }

        .gi-kpi-label {
            display: flex;
            justify-items: center;
            align-items: center;
            width: 13.8vw;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        .gi-kpi-value {
            display: flex;
            justify-items: center;
            font-weight: $font-weight-semibold;
            font-size: 3rem;
            line-height: 4rem;
            color: var(--primary-color);
            border-left: 1px solid #e5e5e5;
            padding-left: 1.75rem;
        }
    }

    &-schedule {
        display: flex;
        gap: 1.5rem;
        margin-bottom: 1.5rem;

        &-week {
            background: var(--primary-color-bleached);
            border: 1px solid var(--primary-color);
            border-radius: 5px;
            padding: 12px 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-family: 'Open Sans';
                font-weight: 400;
                font-size: 14px;
                color: var(--primary-color);
                b {
                    font-weight: 600;
                }
            }

        }

        &-table {
            padding: 24px 32px;
            border: 1px solid #E5E5E5;
            border-radius: 8px;
            width: 70%;

            hr {
                margin-top: 0;
                margin-bottom: 1rem;
            }

            &-header {
                display: flex;
                justify-content: space-between;

                .total {
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 14px;
                    color: #202020;
                    b {
                        font-weight: 600;
                    }
                }

                .date {
                    font-family: "Poppins";
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--primary-color);
                    margin-bottom: 0.5rem;
                }
            }

            table {
                width: 100%;
                thead {
                    margin-bottom: 1rem;
                    th {
                        font-family: 'Open Sans';
                        font-weight: 600;
                        font-size: 14px;
                        color: #202020;
                    }
                }

                tbody {
                    td {
                        font-family: 'Open Sans';
                        font-weight: 400;
                        font-size: 14px;
                        color: #202020;
                    }
                }
            }
        }
    }

    &-schedule .section-body {
        flex-flow: column !important;
    }

    .schedule-section .section-body,
    &-schedule .section-body {
        display: flex;
        flex-flow: row nowrap;
        box-sizing: border-box;
        padding: 24px 32px;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        width: 30%;

        .MuiPickersToolbar-root,
        .MuiPickersCalendarHeader-switchViewButton,
        .MuiDialogActions-root {
            display: none;
        }

            .MuiPickersCalendarHeader-labelContainer {
                position: absolute;
                left: 3rem;
                right: 3rem;
                top: 0;
                bottom: 0;
            }

            .MuiPickersCalendarHeader-root {
                display: flex;
                align-items: center;
                justify-items: center;
                position: relative;
            }

            .MuiPickersCalendarHeader-root:first-child {
                order: 0;
                padding-right: 20px;
                padding-left: 20px;
            }

            .MuiPickersArrowSwitcher-root {
                display: inline-flex;
            }

            .MuiPickersCalendarHeader-label {
                text-align: center;
                width: 100%;
            }

            .MuiPickersArrowSwitcher-spacer {
                width: 220px;
            }

            .css-31ca4x-MuiPickersFadeTransitionGroup-root,
            .MuiPickersFadeTransitionGroup-root {
                display: flex;
                width: 100%;
            }

            .css-9reuh9-MuiPickersArrowSwitcher-root {
                margin-left: -2px;
            }

            .MuiPickersArrowSwitcher-button {
                padding-right: 7px;
                color: #202020
            }
            .MuiPickersArrowSwitcher-button.Mui-disabled {
                color: rgba(0, 0, 0, 0.26) !important;
            }
            .MuiDayPicker-weekDayLabel {
                width: 30px;
                height: 30px;
                font-family: 'Open Sans';
                font-weight: 400;
                font-size: 10px;
                color: #A3A3A3;
            }

            .MuiDayPicker-weekContainer,
            .MuiDayPicker-header {
                gap: 0.5rem;
            }

        .cell {
            cursor: pointer;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.66;
            width: 30px;
            height: 30px;
            margin: 0 2px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }

        .cell-today {
            @extend .cell;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 0.875rem;
            text-align: center;
            color: #202020;
        }

        .cell-transparent {
            @extend .cell;
            color: transparent;
            pointer-events: none;
        }

        .cell-selected {
            @extend .cell;
            border-radius: 5px;
            background-color: var(--primary-color-bleached);
            color: var(--primary-color);
        }

        .MuiBadge-badge {
            top: 100%;
            right: 50%;
            width: 0.25rem;
            min-width: 0.25rem;
            height: 0.25rem;
        }

        .before-today .MuiButtonBase-root {
            color: $silver-chalice;
        }

        .before-today .Mui-selected .MuiButtonBase-root {
            color: inherit;
        }

        .cell-transparent .MuiButtonBase-root {
            color: transparent;
            pointer-events: none;
        }

        .custom-schedules {
            justify-content: center;
            display: flex;
            flex-flow: column nowrap;
            padding: 2rem;
            border-left: 1px solid $silver-chalice;
            margin-left: 2rem;
            width: max-content;
        }

        .custom-schedules-date {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.375rem;
            color: var(--primary-color);
        }

        .custom-schedules-actions {
            button {
                margin-left: 1rem;
                color: var(--primary-color);

                &:disabled {
                    color: var(--disabled-color);
                }
            }
        }

        .custom-schedules-body {
            display: flex;
            flex-flow: column wrap;
        }

        .input-container {
            display: flex;
            flex-flow: column nowrap;
            // flex: 0 0 50%;
            //max-width: 50%;
            margin-bottom: 0.5rem;
            font-size: 0.875rem;
            font-weight: $font-weight-normal;
            line-height: 1.1875rem;

            label {
                margin-bottom: 0.2rem;
                font-weight: $font-weight-semibold;
                color: $silver-chalice;
                font-size: 0.875rem;
                text-transform: uppercase;

                @media (max-height: 767.98px) {
                    margin-bottom: 0;
                }
            }

            @include bootstrap.media-breakpoint-down(md) {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 0.5rem;
                margin-bottom: 1rem;
            }
        }

        .PrivatePickersToolbar-root {
            display: none;
        }

        .transparent-text {
            color: transparent;
        }
    }

    .filter-section {
        max-width: 270px;
    }

    .section-title {
        font-weight: $font-weight-semibold;
        color: var(--text-color);
        font-size: 1.25rem;
        line-height: 1.7025rem;
        padding-bottom: 0.5rem;
        margin-bottom: 0;

        @media (max-height: 767.98px) {
            padding-bottom: 0;
            margin-bottom: 0.2rem;
        }
    }
}
