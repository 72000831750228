@use "../global/_global-dir" as *;

.efz-alert {
    border-radius: 3px !important;
    font-size: 14px;
    font-weight: 400;
    padding: 0.875rem 1rem;
    align-items: center;
    font-size: 0.875rem;
    .MuiAlert-message {
        color: $neutral-800;
        font-style: $font-style-normal;
        font-weight: $font-weight-normal;
        font-size: 0.875rem;
        line-height: 1.188rem;
    }

    &-error {
        @extend .efz-alert;
        .MuiAlert-icon {
            color: $efz-red-400 !important;
        }
        border: 1px solid $efz-red-400 !important;
        background-color: $efz-red-50 !important;
    }
    &-info {
        @extend .efz-alert;
        .MuiAlert-icon {
            color: $info-600 !important;
        }
        border: 1px solid $info-600 !important;
        background-color: $info-50 !important;
    }
    &-success {
        @extend .efz-alert;
        .MuiAlert-icon {
            color: $success-600 !important;
        }
        border: 1px solid $success-600 !important;
        background-color: $success-50 !important;
    }
    &-warning {
        @extend .efz-alert;
        .MuiAlert-icon {
            color: $warning-600 !important;
        }
        border: 1px solid $warning-600 !important;
        background-color: $warning-50 !important;
    }
    &-title {
        font-size: 0.875rem;
        margin: 0 0 0 2rem;
    }
}
