@use "../global/_global-dir" as *;

.process-table {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    color: #000000 !important;

    .table-hover {
        min-width: 100% !important;
        @media screen and (max-width: 768px) {
            min-width: 720px !important;
            min-height: 500px !important;
        }
    }

    .min-resolution-on {
        display: none !important;
    }
    .min-resolution-off {
        display: block !important;
    }

    @media screen and (max-width: 768px) {
        .min-resolution-on {
            display: block !important;
        }
        .min-resolution-off {
            display: none !important;
        }
    }

    .thead {
        border-radius: $border-radius;
        border: 1px solid #2020203c;
        background-color: #e5e5e5;
        color: var(--text-color);
        font-weight: $font-weight-semibold;
        // margin-right: 6px; // because of scrollbar
        position: sticky;
        top: 0;
        z-index: 1050;

        .th {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            font-size: 0.875rem;
        }

        .header-scroller {
            width: 100%;
            overflow-x: hidden;
            flex: 1;
        }
    }

    .body-scroller,
    .header-scroller {
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .body-scroller {
        position: relative;
        display: flex;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        min-height: calc(100vh - 330px - 2rem);
        z-index: 1;
        overflow: auto;

        .tbody {
            width: -webkit-fill-available;
            width: -moz-available; // For Firefox
        }
        @media screen and (max-width: 768px) {
            min-height: calc(85vh - 220px - 0.5rem);

            .td {
                align-items: center !important;
            }
        }

        .tr {
            margin: 0.5rem 0;
            border-radius: $border-radius;
            border: 1px solid #e5e5e5;
            background-color: var(--paper-color);
            font-size: 0.875rem;

            @media screen and (max-width: 768px) {
                min-width: 700px !important;
            }
        }

        .td {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0.5rem;
            min-height: 3.75rem;
        }

        .tr:hover {
            background-color: rgba($black, 0.05);
        }

        // Quando for para aplicar o mockup
        // .tr:hover {
        //     background-color: var(--primary-color-bleached);
        //     border-color: var(--primary-color);

        //     .td,
        //     .process-action-button.btn {
        //         color: var(--primary-color);
        //     }
        // }
    }

    .tfooter {
        height: 60px;
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 768px) {
            min-width: 700px !important;
        }
    }

    .process-details-button,
    .process-action-button,
    .process-action-button-v2 {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &:hover {
            color: var(--primary-color);

            svg path {
                fill: var(--primary-color);
            }
        }

        &-disabled {
            @extend .process-action-button;
            pointer-events: none;
            cursor: default;

            svg {
                path {
                    fill: var(--disabled-color);
                }
            }
        }

        &-download {
            font-size: 0.875rem;
            text-transform: uppercase;
            text-decoration: underline;
            margin-left: 0.25rem;

            &-svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }

        &-details {
            padding: 0.75rem 1rem;
            font-size: 0.875rem;
        }
    }

    .process-action-button-v2-details {
        border: 1px solid;
    }

    .refresh-button {
        margin-left: 0.5rem;
        min-width: 0;
    }

    .bottom-loading {
        display: flex;
        margin-left: 2rem;
        min-width: 50px;
    }

    .pagination-buttons {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 1rem;

        .go-page {
            &-enabled {
                path {
                    fill: #202020;
                }
                line {
                    stroke: #202020;
                }
            }
            &-disabled {
                path {
                    fill: #a3a3a3;
                }
                line {
                    stroke: #a3a3a3;
                }
            }
        }

        @media (max-width: 992px), (max-height: 767.98px) {
            margin: 0 0 0 1.5rem;
        }
    }

    // React Placeholder
    &-row-placeholder {
        .text-block {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            .text-row {
                min-height: 2.5rem !important;
                width: 100% !important;
            }
        }
    }
}
