@use "../../global/_global-dir" as *;

.calendar-icon {
    path {
        fill: #A3A3A3;
    }
}

.form-update-state {
    .input-container {
        display: flex;
        width: 100%;
        padding: 0.5rem;
        flex-flow: column nowrap;
        margin-bottom: 0.8rem;

        label {
            font-size: 0.875rem;
            font-weight: $font-weight-normal;
            margin-bottom: 0.5rem;
        }
    }

    .input-section {
        display: flex;
        flex-flow: column nowrap;
    }

    .section-actions {
        gap: 1rem;
        margin: 0.7rem auto;

    }
}

.form-process-confirm-dispatch {
    .MuiDialog-paper {
        background-color: var(--background-color);
        width: 39vw;
    }

    .MuiDialogContent-root {
        padding: 0 3rem;
    }

    .MuiDialogTitle-root {
        display: flex;
        justify-content: center;
        h2 {
            font-weight: $font-weight-bold !important;
            font-size: 1rem;
            line-height: 1.375rem;
        }
    }

    .MuiDialogContentText-root {
        font-weight: $font-weight-normal;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: center;
    }

    .MuiDialogActions-root {
        justify-content: center;
    }
}

.form-process-update_equipment-dialog {
    .MuiDialog-paper {
        background-color: var(--background-color);
        width: 39vw;
    }

    .MuiDialogContent-root {
        padding: 0 5rem;
    }

    .MuiDialogTitle-root {
        display: flex;
        justify-content: center;
        h2 {
            font-size: 1.25rem;
            line-height: 1.375rem;
            color: var(--primary-color);
            text-transform: uppercase;
        }
    }

    .MuiDialogContentText-root {
        font-weight: $font-weight-normal;
        font-size: 1rem;
        line-height: 1.375rem;
        text-align: center;
    }

    .MuiDialogActions-root {
        justify-content: center;
        margin-bottom: 2.375rem;
        gap: 1.5rem;
    }
}

.form-process-update-state {
    .MuiDialog-paper {
        background-color: var(--background-color);
        width: 600px;

        display: flex;
        justify-content: center;
        h2 {
            font-weight: $font-weight-bold !important;
            font-size: 1rem !important;
            line-height: 1.375rem !important;
            text-align: center;
        }
    }

    .Mui-focused .MuiSelect-root {
        color: var(--primary-color);
    }

    .MuiSelect-root {
        background: #f5f5f5;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 3px 3px 0px 0px;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
    }

    .text-input {
        background: #ffffff;
        border: 1px solid #e5e5e5 !important;
        box-sizing: border-box;
        border-radius: 3px;
    }

    .section-actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .file-limit-warning {
        display: flex;
        align-items: center;
        color: $error-600;

        svg {
            path {
                fill: $error-600;
            }
        }
    }
}

.form-process-detail {
    .link {
        cursor: pointer;
    }

    .link:hover {
        color: var(--primary-color);
    }

    .underline {
        text-decoration: underline;
    }

    .divider {
        margin-left: 2rem;
        margin-right: 2rem;
        border-right: 1px dashed rgba(196, 196, 196, 0.5);
    }
    .form-header {
        width: 100%;
        display: flex;
        position: relative;
        padding: 2rem 3.375rem;

        .MuiDialogTitle-root {
            padding: 0 !important;
        }

        &-title {
            h2 {
                font-size: 1rem;
                font-weight: $font-weight-semibold;
                line-height: 21px;
            }

            hr {
                width: 70px;
                margin-top: 0.5rem;
                margin-bottom: 0;
                border-top: 3px solid var(--primary-color);
                float: left;
            }
        }

        &-close {
            position: absolute;
            margin: 12px; // padding 12px of MuiIconButton-root + margin 12px to be 24px TOTAL
            top: 0;
            right: 0;
        }
    }

    .form-sections {
        display: flex;
        padding: 0rem 3.375rem 2rem 3.375rem;
    }

    .form-section {
        width: 280px;

        &-title {
            margin-bottom: 0.75rem;
            h2 {
                color: var(--primary-color);
                font-size: 0.875rem;
                font-weight: $font-weight-semibold;
            }

            hr {
                margin-top: 0.5rem;
                margin-bottom: 0;
            }
        }

        .input-container,
        .input-container-upload {
            display: flex;
            width: 100%;
            flex-flow: column nowrap;
            margin-bottom: 0.8rem;

            > div {
                line-break: anywhere;
            }

            label {
                font-size: 0.875rem;
                font-weight: $font-weight-semibold;
                margin-bottom: 0.5rem;
            }

            .sub-label {
                font-size: 0.875rem;
                font-weight: $font-weight-normal;
                margin-bottom: 0.5rem;
            }

            .view-budget-label {
                margin-bottom: 0.2rem;
                font-weight: $font-weight-semibold;
                color: $silver-chalice;
                font-size: 0.875rem;
                text-transform: uppercase;

                @media (max-height: 767.98px) {
                    margin-bottom: 0;
                }
            }

            .dropzone {
                margin-bottom: 0;
            }

            .dropzone-content {
                table {
                    width: 100%;
                }
                p {
                    margin-bottom: 0;
                }
            }

            .MuiInputAdornment-root {
                color: var(--disabled-color);
            }

            .docs-list {
                font-weight: $font-weight-normal !important;

                p {
                    margin: 0rem;
                }
                span {
                    font-size: 0.875rem;
                    color: var(--primary-color);
                    text-decoration: underline;
                }

                .filename-item {
                    padding: 0;
                    margin-bottom: 0.5rem;
                }

                .filename-title {
                    margin-left: 0.5rem;
                }

                .icon-style path {
                    fill: var(--primary-color);
                }

                .MuiListItemAvatar-root {
                    min-width: auto;
                }

                .MuiList-padding {
                    padding: 0rem !important;
                }
            }

            .templates-curve-load-list {
                display: flex;
                flex-direction: column;
            }

            .curve-load-download {
                display: flex;
                align-items: center;
                margin-bottom: 0.6rem;
                color: var(--primary-color);
                cursor: pointer;
                svg {
                    margin-right: 0.5rem;
                }

                &-disabled {
                    @extend .curve-load-download;
                    color: var(--disabled-color);
                    pointer-events: none;
                    cursor: auto;
                }

                :hover {
                    text-decoration: underline;
                }
            }

            .invalid-format {
                color: red;
                line-break: normal;
            }
        }

        .input-section {
            display: flex;
            flex-flow: column nowrap;

            &-radio {
                label {
                    margin-right: 1.5rem;
                    span {
                        padding: 0;
                        margin-left: 0.5rem;
                    }
                }
            }
        }

        .section-actions {
            margin: 0.7rem auto;

            .cancel-button {
                margin-right: 1rem;
            }
        }
    }

    .section-actions {
        margin-bottom: 2rem;
    }

    .MuiDialogContent-root {
        padding: 0;
    }
    .MuiDialog-paperScrollPaper {
        // To remove max height
        max-height: 100%;
    }
}

.reformulated-dialog {
    .MuiDialog-paper {
        overflow: hidden !important; // To remove scroll
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
