.container-check-success {
    width: auto;
    height: auto;
    margin: 0 auto;
    vertical-align: middle;

    .check-icon {
        width: 80px;
        height: 80px;
        border: 4px solid gray;
        -webkit-border-radius: 40px;
        border-radius: 40px;
        border-radius: 50%;
        margin: 20px auto;
        padding: 0;
        position: relative;
        box-sizing: content-box;
    }

    .check-icon.check-success {
        border-color: #4CAF50;
    }

    .check-icon.check-success::before, .check-icon.check-success::after {
        content: '';
        -webkit-border-radius: 40px;
        border-radius: 40px;
        border-radius: 50%;
        position: absolute;
        width: 60px;
        height: 120px;
        background: white;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .check-icon.check-success::before {
        -webkit-border-radius: 120px 0 0 120px;
        border-radius: 120px 0 0 120px;
        top: -7px;
        left: -33px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 60px 60px;
        transform-origin: 60px 60px;
    }

    .check-icon.check-success::after {
        -webkit-border-radius: 0 120px 120px 0;
        border-radius: 0 120px 120px 0;
        top: -11px;
        left: 30px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 0px 60px;
        transform-origin: 0px 60px;
    }

    .check-icon.check-success .placeholder {
        width: 80px;
        height: 80px;
        border: 4px solid rgba(76, 175, 80, .5);
        -webkit-border-radius: 40px;
        border-radius: 40px;
        border-radius: 50%;
        box-sizing: content-box;
        position: absolute;
        left: -4px;
        top: -4px;
        z-index: 2;
    }

    .check-icon.check-success .fix {
        width: 5px;
        height: 90px;
        background-color: white;
        position: absolute;
        left: 28px;
        top: 8px;
        z-index: 1;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .check-icon.check-success.animate::after {
        -webkit-animation: rotatePlaceholder 4.25s ease-in;
        animation: rotatePlaceholder 4.25s ease-in;
    }

    .check-icon.check-success {
        border-color: transparent\9;
    }

    .check-icon.check-success .line .tip {
        transform: rotate(45deg) \9;
        -ms-transform: rotate(45deg) \9;
    }

    .check-icon.check-success .line .long {
        transform: rotate(-45deg) \9;
        -ms-transform: rotate(-45deg) \9;
    }

    .animateSuccessTip {
        -webkit-animation: animateSuccessTip 0.75s;
        animation: animateSuccessTip 0.75s;
    }

    .animateSuccessLong {
        -webkit-animation: animateSuccessLong 0.75s;
        animation: animateSuccessLong 0.75s;
    }

    @-webkit-keyframes animateSuccessLong {
        0% {
            width: 0;
            right: 46px;
            top: 54px;
        }
        65% {
            width: 0;
            right: 46px;
            top: 54px;
        }
        84% {
            width: 55px;
            right: 0px;
            top: 35px;
        }
        100% {
            width: 47px;
            right: 8px;
            top: 38px;
        }
    }
      
    @-webkit-keyframes animateSuccessTip {
        0% {
            width: 0;
            left: 1px;
            top: 19px;
        }
        54% {
            width: 0;
            left: 1px;
            top: 19px;
        }
        70% {
            width: 50px;
            left: -8px;
            top: 37px;
        }
        84% {
            width: 17px;
            left: 21px;
            top: 48px;
        }
        100% {
            width: 25px;
            left: 14px;
            top: 45px;
        }
    }
      @keyframes animateSuccessTip {
        0% {
          width: 0;
          left: 1px;
          top: 19px;
        }
        54% {
          width: 0;
          left: 1px;
          top: 19px;
        }
        70% {
          width: 50px;
          left: -8px;
          top: 37px;
        }
        84% {
          width: 17px;
          left: 21px;
          top: 48px;
        }
        100% {
          width: 25px;
          left: 14px;
          top: 45px;
        }
      }
      
      @keyframes animateSuccessLong {
        0% {
          width: 0;
          right: 46px;
          top: 54px;
        }
        65% {
          width: 0;
          right: 46px;
          top: 54px;
        }
        84% {
          width: 55px;
          right: 0px;
          top: 35px;
        }
        100% {
          width: 47px;
          right: 8px;
          top: 38px;
        }
      }
      
      .check-icon.check-success .line {
        height: 5px;
        background-color: #4CAF50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 2;
      }
      
      .check-icon.check-success .line.tip {
        width: 25px;
        left: 14px;
        top: 46px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      
      .check-icon.check-success .line.long {
        width: 47px;
        right: 8px;
        top: 38px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      
      @-webkit-keyframes rotatePlaceholder {
        0% {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
        5% {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
        12% {
          transform: rotate(-405deg);
          -webkit-transform: rotate(-405deg);
        }
        100% {
          transform: rotate(-405deg);
          -webkit-transform: rotate(-405deg);
        }
      }
      @keyframes rotatePlaceholder {
        0% {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
        5% {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
        12% {
          transform: rotate(-405deg);
          -webkit-transform: rotate(-405deg);
        }
        100% {
          transform: rotate(-405deg);
          -webkit-transform: rotate(-405deg);
        }
      }

}