@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

.create-process {
    &.update-details {
        margin: 0 !important;
    }
    .equipment-group {
        margin-top: 0.5rem;
        padding: 0;
        border: none;

        .MuiAccordionSummary-root {
            flex-direction: row-reverse;
            column-gap: 1rem;
            background-color: #f5f5f5;
            border-radius: 3px;
            margin-bottom: 0.75rem;
            min-height: unset;

            .MuiAccordionSummary-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $neutral-800;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 19px;

                .technical-details-edit-btn {
                    width: 25px;
                    height: 25px;
                    padding: 3px;
                }
            }
        }
    }
    .add-new-equipment {
        text-transform: none;
        font-family: 'Poppins';
        font-size: 0.875rem;
    }
    &.flex-grid {
        display: flex;
        flex-wrap: wrap;
        column-gap: 2rem;
        .generic-form-input {
            flex: 0 0 calc(100%/3.5);
        }
    }
    .tab-container {
        display: grid;
        grid-template-columns: 0.5fr 1fr 0.5fr;
        grid-template-rows: 1fr;
        width: 100%;

        span:nth-child(1) {
            grid-column-start: 2;
        }

        &-end {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    &-dropdowns {
        margin-bottom: 1.5rem;

        &-facility,
        &-client {
            .dropdown {
                height: 100%;
            }
            .dropdown-header {
                width: 100%;
                border: 1px solid $neutral-300;
                background-color: #ffffff;
                padding: 1rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 5px;
                height: 100%;

                &.highlight {
                    @extend .dropdown-header;
                    border: 1px solid var(--primary-color);
                    background-color: var(--primary-color-bleached);
                    .description-title {
                        color: var(--primary-color);
                    }
                }

                &.disabled {
                    background-color: var(--disabled-color);
                }

                &.selected {
                    border-radius: 5px 5px 0 0 !important;
                }

                &.block-dropdown {
                    pointer-events: none;
                }

                &:focus {
                    box-shadow: none;
                }

                &-icon {
                    margin-right: 1rem;
                }

                &-description {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;

                    .description-title {
                        font-size: 0.875rem;
                        line-height: 21px;
                        color: $neutral-800;
                        max-width: 90%;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        span {
                            margin-bottom: 3px;
                        }

                        &-selected {
                            @extend .description-title;
                            font-family: "Poppins";
                            color: var(--primary-color);
                        }

                        &.bold {
                            @extend .description-title;
                            font-family: "Poppins";
                            font-weight: $font-weight-medium;
                        }

                        &-go {
                            @extend .description-title;
                            font-size: 0.75rem;
                            text-transform: uppercase;
                            line-height: 16.34px;
                            margin-top: 5px;
                            pointer-events: auto;
                            text-decoration-line: underline;

                            &:hover {
                                color: var(--primary-color);
                            }
                        }

                        &.description-placeholder {
                            @extend .description-title;
                            font-family: "Poppins";
                            font-weight: $font-weight-normal;
                        }
                    }
                }

                &-arrow {
                    margin-left: auto;
                    position: absolute;
                    right: 1rem;

                    &-selected {
                        @extend .dropdown-header-arrow;
                        transition: 0.3s transform;
                        transform: rotate(180deg);
                        svg {
                            path {
                                fill: var(--primary-color);
                            }
                        }
                    }
                }
            }

            .dropdown-menu {
                width: 100%;
                border: none;
                border-left: 1px solid $neutral-300;
                border-right: 1px solid $neutral-300;
                border-bottom: 1px solid $neutral-300;
                border-radius: 0 0 5px 5px;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07);
                padding: 0;

                .autocomplete-dropdown {
                    .MuiAutocomplete-root {
                        padding: 1rem !important;
                    }

                    .MuiTextField-root {
                        width: 100%;
                    }

                    .MuiInputBase-root {
                        padding: 10px !important;
                    }

                    .MuiInputAdornment-root {
                        margin-right: 0;
                    }

                    .MuiAutocomplete-popper {
                        width: 100% !important;
                        position: inherit !important;
                        border-top: 1px solid var(--divider-color);
                        background-color: #f5f5f5;
                        transform: inherit !important;

                        .MuiAutocomplete-paper {
                            margin: 0 !important;
                        }

                        .MuiAutocomplete-listbox {
                            overflow-x: hidden !important;
                            padding: 0;

                            &::-webkit-scrollbar-track,
                            &::-webkit-scrollbar-track-piece {
                                background-color: #f5f5f5;
                            }
                        }
                    }

                    .MuiAutocomplete-option {
                        padding: 0.75rem 1rem !important;
                        color: initial;
                        border-bottom: 1px solid var(--divider-color);
                        height: auto;

                        &:last-child {
                            border-bottom: 0;
                        }

                        &:hover {
                            background-color: var(--primary-color) !important;

                            h5,
                            h6 {
                                color: var(--paper-color) !important;
                            }
                        }

                        span {
                            color: var(--text-color);
                            text-overflow: ellipsis !important;
                            overflow: hidden !important;
                            white-space: nowrap;
                            font-weight: $font-weight-normal;
                        }
                    }

                    .MuiAutocomplete-noOptions,
                    .MuiAutocomplete-loading {
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                    }

                    .autocomplete-dropdown-option-wrapper {
                        display: flex;
                        flex-flow: column nowrap;

                        @include bootstrap.media-breakpoint-down(xl) {
                            max-width: 100%;
                        }

                        & > h5 {
                            color: var(--text-color);
                            margin: 0;
                            font-weight: $font-weight-normal;
                            align-self: flex-start;
                            font-size: 0.875rem;
                        }

                        & > h6 {
                            color: var(--text-color-secondary);
                            margin: 0;
                            font-weight: $font-weight-normal;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-size: 0.75rem;
                        }

                        &:hover {
                            color: var(--paper-color) !important;
                        }
                    }

                    .autocomplete-loader {
                        flex: unset;
                        min-width: 0;
                    }

                    .solution-autocomplete-search {
                        path {
                            fill: var(--primary-color);
                            stroke: var(--primary-color);
                        }
                    }
                }

                &-button {
                    border-top: 1px solid $neutral-300;
                    span {
                        font-size: 0.75rem;
                        color: $neutral-800;
                        line-height: 16.34px;
                    }

                    .add-icon {
                        path {
                            fill: var(--primary-color);
                        }
                    }

                    &.see-all {
                        text-align: center;
                    }
                }
            }

            .add-facility-button {
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: row nowrap;
                gap: 0.625rem;
                font-size: 0.875rem;
                font-family: "Poppins";
                font-weight: $font-weight-normal;
                text-transform: none;
                color: var(--primary-color);
                background-color: var(--primary-color-bleached);
                border: 1px solid var(--primary-color);
            }
        }
    }
    &-solutions {
        margin-bottom: 1.5rem;

        &-title {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            line-height: 19px;
            color: $neutral-800;
            margin-bottom: 1rem;
        }

        &-subtitle {
            @extend .create-process-solutions-title;
            margin-bottom: 0.5rem;
        }

        &-card {
            background-color: var(--paper-color);
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;

            &-left {
                width: clamp(78px, 13.33%, 84px);

                .solution-card-image {
                    height: 84px;
                    border-radius: 0.625rem;
                    overflow: hidden;
                    position: relative;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            &-right {
                display: flex;
                flex-direction: column;
                flex: 1;
                height: 100%;

                .solution-card-first-line {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 0.625rem;
                    margin-bottom: 12px;

                    .solution-card-icon {
                        width: 30px;
                        height: 30px;
                    }

                    .solution-card-title {
                        font-size: 1.125rem;
                        line-height: 2.125rem;
                        margin: 0;
                        font-family: "Poppins";
                    }
                }

                .solution-card-second-line {
                    max-width: 270px;
                    font-size: 0.875rem;
                }
            }

            &.selected {
                border-color: var(--primary-color);
                background-color: var(--primary-color-bleached);

                .solution-card-title {
                    color: var(--primary-color);
                }
            }

            .MuiSkeleton-root {
                transform: none !important;
            }

            &-placeholder {
                @extend .create-process-solutions-card;

                .image-placeholder {
                    @extend .solution-card-image;
                    width: 78px;
                    height: 100%;
                }

                &-description {
                    display: flex;
                    flex-direction: column;
                    row-gap: 12px;
                    height: 100%;

                    .title-placeholder {
                        width: 120px;
                        height: 34px;
                    }
                    .description-placeholder {
                        width: 270px;
                        height: 38px;
                    }
                }
            }
        }

        .dropzone {
            margin-bottom: 1.5rem;
        }

        .dropzone-card,
        &-comments .MuiOutlinedInput-root {
            width: 100%;
            max-width: 300px;
        }
    }
    &-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1.5rem;
        min-height: 6.25rem;

        @include bootstrap.media-breakpoint-down(lg) {
            grid-template-columns: repeat(1, 1fr);
        }

        &-solutions {
            @extend .create-process-grid;

            @include bootstrap.media-breakpoint-down(xl) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
