@use "../global/_global-dir" as *;

.home-container {
    display: flex;
    flex-flow: row;
    padding: 0 !important;
    max-width: 100vw;
    position: relative;

    .ornaments-svg {
        display: flex;
        flex-flow: row;
        flex: 1;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .full-svg {
            width: 100%;
            svg {
                width: 100%;
                height: 100%;
            }
        }

        .ornament-bottom-container {
            display: flex;
            width: 100%;
            min-height: 100%;
            align-items: flex-end;
            justify-content: center;
            position: absolute;
        }
    }

    .home-content-container {
        display: flex;
        width: 100%;
        min-height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;

        .text-input {
            margin-top: 2px;
            max-width: 65%;
            min-width: 65%;
            margin-right: 1rem;
            background-color: var(--paper-color);

            fieldset {
                top: -12px;
            }
        }
    }

    .content-container {
        display: flex;
        flex-flow: column;
        max-width: 500px;
        width: 500px;
        transition: width ease 0.3s;
        margin-bottom: 8%;

        @media (max-width: 500px) {
            max-width: 400px;
            width: 400px;
        }

        h3 {
            font-weight: $font-weight-semibold;
            font-size: 1.3rem;
            line-height: 25px;
            text-align: left;
            line-height: 1.8rem;
        }

        span {
            font-weight: $font-weight-light;
            font-size: 1.05rem;
            line-height: 1.6rem;
        }

        .home-content-hiperlinks {
            margin-top: 2.5rem;

            span {
                margin-bottom: 1rem;
            }

            a {
                text-decoration: underline;
                color: var(--text-color);
                font-size: 0.875rem;
                line-height: 1.25rem;
            }
        }
    }
}
