@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

.custom-card {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    box-shadow: $shadow-lg;
    max-width: 400px;
    min-width: 275px;
    margin-bottom: 1rem;
    margin-left: 1rem;
    border-radius: $border-radius;

    @include bootstrap.media-breakpoint-down(sm) {
        margin-left: 0;
    }

    &:first-child {
        margin-left: 0;
    }

    .custom-card-header.company-recommend {
        background: var(--primary-color);
    }

    .custom-card-header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background: bootstrap.$gray-600;
        padding: 25px;
        color: #fff;

        .header-title {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            h2 {
                margin-bottom: 2rem;
                font-size: 1.6rem;
                font-weight: $font-weight-semibold;
                line-height: 1.2;
                text-align: center;
            }

            ul {
                display: flex;
                flex-flow: row nowrap;
                padding: 0px;
                margin: 0px;
                list-style: none;
                width: 100%;
            }

            li {
                display: flex;
                flex-flow: column nowrap;
                border-right: 1px solid var(--divider-color);
                padding: 0 1rem;
                justify-content: center;
                align-items: center;
                flex: 1 1 50%;

                h5 {
                    font-size: 0.8rem;
                    opacity: 0.5;
                    text-transform: uppercase;
                    font-weight: bold;
                    text-align: center;
                }

                p {
                    font-size: 1.1rem;
                    font-weight: $font-weight-normal;
                    margin: 0;
                }
            }

            li:last-child {
                padding-right: 0px;
                border: 0px;
            }
        }
    }

    .custom-card-body {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        background: var(--paper-color);
        padding: 30px;
        display: flex;
        flex-flow: column;
        justify-content: center;

        .card-kpis {
            padding-bottom: 20px;
            border: 0;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            .label {
                color: var(--text-color-secondary);
                opacity: 0.6;
                margin-bottom: 5px;
                font-size: 0.8rem;
                text-transform: uppercase;
                font-weight: bold;
                padding: 0;
                border: 0;
            }

            .value,
            .value span {
                color: var(--text-color);
                line-height: 1.75rem;
                padding: 0;
                border: 0;
                font-weight: $font-weight-normal;
            }
        }

        .custom-button {
            width: 75%;
            margin: 0 auto;
        }
    }
}
