@use "../global/_global-dir" as *;

// CSS for SideNavigator Component - Common

.side-navigator {
    .go-back-button {
        position: absolute;
        left: -55px;
        z-index: 1000;
        cursor: pointer;
        display: flex;
        height: 100px;
        width: 100px;
        border-radius: 0 100px 100px 0;
        background-color: var(--primary-color);
        align-items: center;
        justify-content: flex-end;
        padding: 15px;
        top: 50vh;
        transition: all ease 0.5s;

        &:hover {
            transition: all ease 0.5s;
            left: -20px;
        }

        .page-icon {
            color: white;
            margin-left: 0.75rem;

            & > svg {
                width: 24px;
                height: 24px;
            }
        }

        &.disabled {
            pointer-events: none;
            cursor: default;
            background-color: $gray-default;
        }
    }

    .go-forward-button {
        position: absolute;
        right: 0;
        z-index: 1000;
        cursor: pointer;
        display: flex;
        height: 100px;
        width: 50px;
        border-radius: 100px 0 0 100px;
        background-color: var(--primary-color);
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        top: 50vh;
        transition: all ease 0.5s;

        &:hover {
            transition: all ease 0.5s;
            width: 85px;

            & > svg {
                color: white;
            }
        }

        &.disabled {
            pointer-events: none;
            cursor: default;
            background-color: $gray-default;
        }

        & > svg {
            transform: rotate(180deg);
            color: transparent;
            transition: color ease 0.5s;
        }

        .page-icon {
            color: white;
            margin-right: 0.75rem;

            & > svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
