.selected-radio-icon {
    circle {
        &:first-of-type {
            stroke: var(--primary-color);
        }

        &:last-of-type {
            fill: var(--primary-color);
        }
    }
}