// EDP COLORS
$edp-red-50: #FEF3F4;
$edp-red-100: #FEEFF0;
$edp-red-200: #FCD0D5;
$edp-red-300: #F8A2AB;
$edp-red-400: #F57381;
$edp-red-500: #F14557;
$edp-red-600: #EE162D;
$edp-red-700: #D61429;
$edp-red-800: #BE1224;
$edp-red-900: #A70F1F;

//EFZ BRAND COLORS
// RED VELVET
$efz-red-50: #FDF6F5;
$efz-red-100: #FAECEC;
$efz-red-200: #F6D9D8;
$efz-red-300: #F1C7C5;
$efz-red-400: #E8A19E;
$efz-red-500: #DE7B77;
$efz-red-600: #D0433C;
$efz-red-700: #BB3C36;
$efz-red-800: #A63630;
$efz-red-900: #922F2A;

// LEMON PIE
$efz-lemon-50: #FBF9EA;
$efz-lemon-100: #F7F3D4;
$efz-lemon-200: #EFE7A9;
$efz-lemon-300: #EBE194;
$efz-lemon-400: #E6DA7E;
$efz-lemon-500: #DECE53;
$efz-lemon-600: #D6C228;
$efz-lemon-700: #C1AF24;
$efz-lemon-800: #AB9B20;
$efz-lemon-900: #96881C;

// CHALKBOARD
$efz-chalkboard-50: #EDECED;
$efz-chalkboard-100: #DADADA;
$efz-chalkboard-200: #C8C7C8;
$efz-chalkboard-300: #918F91;
$efz-chalkboard-400: #6D696C;
$efz-chalkboard-500: #5A5759;
$efz-chalkboard-600: #484447;
$efz-chalkboard-700: #413D40;
$efz-chalkboard-800: #323032;
$efz-chalkboard-900: #242224;

//FEEDBACK COLORS
// ERROR
$error-50: #FDF6F5;
$error-100: #FAECEC;
$error-200: #F6D9D8;
$error-300: #F1C7C5;
$error-400: #E8A19E;
$error-500: #DE7B77;
$error-600: #D0433C;
$error-700: #BB3C36;
$error-800: #A63630;
$error-900: #922F2A;

// WARNING
$warning-50: #FBF9EA;
$warning-100: #F7F3D4;
$warning-200: #EFE7A9;
$warning-300: #EBE194;
$warning-400: #E6DA7E;
$warning-500: #DECE53;
$warning-600: #D6C228;
$warning-700: #C1AF24;
$warning-800: #AB9B20;
$warning-900: #96881C;

// SUCCESS
$success-50: #EEFAF6;
$success-100: #D8F2EB;
$success-200: #C5ECE1;
$success-300: #9FE0CE;
$success-400: #8BD9C4;
$success-500: #65CDB0;
$success-600: #3EC09C;
$success-700: #39BA96;
$success-800: #329A7D;
$success-900: #25735E;

// INFO
$info-50: #F1F8FF;
$info-100: #D7E7F5;
$info-200: #C3DAF0;
$info-300: #9CC2E6;
$info-400: #74AADC;
$info-500: #609DD7;
$info-600: #3885CD;
$info-700: #2D6AA4;
$info-800: #22507B;
$info-900: #163552;

// STEEL
$steel-50: #f4f5f5;
$steel-100: #eaeced;
$steel-200: #c6cdd2;
$steel-300: #a8b3bb;
$steel-400: #949ca2;
$steel-500: #79838b;
$steel-600: #5d656b;
$steel-700: #49555f;
$steel-800: #3c4d5a;
$steel-900: #2f4455;

// NEUTRALS
$neutral-50: #fafafa;
$neutral-100: #f5f5f5;
$neutral-200: #e5e5e5;
$neutral-300: #dcdcdc;
$neutral-400: #c4c4c4;
$neutral-500: #b1b1b1;
$neutral-600: #a3a3a3;
$neutral-700: #484447;
$neutral-800: #202020;
$neutral-900: #000000;

// BORDER
$border-color: #C4C4C4;
$border-color-50opacity: rgba(196, 196, 196, 0.5);

// DISABLED
$disabled-light: #d7d7d7;