@use "../global/_global-dir" as *;

.docs-download-dialog {
  text-align: center;
  font-size: 1.25rem;
  color: var(--primary-color);
  font-weight: $font-weight-normal !important;
  margin-top: 1.375rem;
  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0rem;
    padding: 1rem;
  }
  p {
    margin: 0rem;
  }
  span {
    font-size: 1rem;
    color: var(--text-color);
  }
}

.docs-list {
  .icon-style path {
    fill: var(--primary-color);
  }
  span {
    font-size: 1rem;
    color: var(--primary-color);
    text-decoration: underline;
  }

  .MuiList-padding {
    padding: 1.6875rem 0rem !important;
  }
}
