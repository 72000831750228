.input-placeholder {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0.5rem;

    .label {
        margin-bottom: 0.5rem;
    }
}

.input-placeholders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}