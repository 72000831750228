@use "../global/_global-dir" as *;

.efz-accordion {
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 2rem 2.75rem;

    &-header {
        display: flex;
        align-items: center;
        column-gap: 0.75rem;
        margin-bottom: 1rem;
        padding-bottom: 6px;
        border-bottom: 1px solid $border-color;

        h3 {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.375rem;
            color: var(--primary-color);
            margin: 0;
            flex-grow: 2;
        }

        .MuiButton-root {
            height: 1.75rem;
            padding: 0 0.65375rem;

            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
        }

        .MuiAccordionSummary-root,
        .efz-button {
            width: 1.75rem;
            aspect-ratio: 1;
            min-height: unset;
            padding: 0;
            border: 1.5px solid var(--primary-color);
            border-radius: 5px;
            justify-content: center;
            box-shadow: none;

            &:hover {
                border-color: var(--primary-color-light);
                background-color: rgba(56, 133, 205, 0.04);
            }

            .MuiAccordionSummary-content {
                display: none;
            }

            svg {
                margin: 0;
            }
        }
    }

    .MuiAccordionDetails-root {
        padding: 0;

        .input-container {
            margin-bottom: 0 !important;
        }
    }
}
