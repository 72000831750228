@use "../global/_global-dir" as *;

//Override
.create-process {
    .MuiTabPanel-root {
        padding: 0;
    }
}

.generic-form-input {
    margin-bottom: 1.5rem;
    label {
        font-family: 'Open Sans';
        font-size: 0.875rem;
        color: $neutral-800;
        margin-bottom: 0.5rem;
    }

    .MuiInputAdornment-root {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 14px;
        color: $neutral-600;
    }
    .MuiInputBase-root.select.selected {
        .MuiSelect-select {
            color: $neutral-800;
        }
    }
    .MuiSelect-select {
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 0.875rem;
        color: $neutral-400;
    }

}