.generic-dialog {

    &-header {
        padding: 1.3rem 2rem 0.7rem 2rem !important;

        h2 {
            font-size: 1.6rem;
        }
    }

    &-body {
        background-color: var(--background-color);
        //filter: brightness(96%);
    }

    &-actions {
        padding: 1.5rem 2rem !important;
    }

    .generic-title {
        .red-line {
            margin-top: 0.5rem;
        }
    }
} 