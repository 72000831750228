@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

.process-details {
    display: flex;
    flex-flow: row nowrap;
    background-color: var(--paper-color);
    border-radius: $border-radius-lg;
    box-shadow: $shadow-xl;
    padding-bottom: 1.5rem;
    margin: 0 6.75rem 2rem 6.75rem;
    justify-content: center;

    @media (max-height: 767.98px) {
        margin: 0rem;
        padding-top: 0;
    }

    @include bootstrap.media-breakpoint-down(lg) {
        flex-flow: column nowrap;
    }

    @media (max-width: 1024px) {
        padding-top: 0;
    }

    .contract-section,
    .timeline-section {
        display: flex;
        flex-flow: column nowrap;
        padding: 0rem;
        padding: 2rem 3rem;
        margin-bottom: auto;

        // @media (max-height: 767.98px) {
        //     padding: 1rem;
        // }

        @include bootstrap.media-breakpoint-down(lg) {
            margin-bottom: 3rem;
        }

        hr {
            margin: 0;
            width: 70px;
            height: 3px;
            background: var(--primary-color);
            margin-bottom: 1.75rem;
            opacity: 1;
            border-top-color: rgba(0, 0, 0, 0.1);
        }

        @include bootstrap.media-breakpoint-down(md) {
            padding: 1.5rem;
        }
    }

    .contract-section {
        min-width: 60%;
        max-width: 60%;
        @media screen and (max-width: 991px) {
            max-width: 100%;
        }
    }

    .cpe-warning {
        margin-bottom: 1rem;

        .efz-alert-warning {
            border-radius: 8px !important;
        }
    }

    .section-technical-summary,
    .section-general-information,
    .section-client-data,
    .section-info-prices,
    .section-technical-details,
    .section-financialInfo,
    .section-accordion {
        align-items: baseline;
        margin-bottom: 1rem;

        &-buttons {
            display: flex;
            align-items: center;
            margin-left: auto;
        }

        .MuiIconButton-root {
            padding: 0;
            margin-left: 0.5rem;
            &:hover {
                background-color: transparent !important;
            }
        }

        &-button {
            path {
                fill: var(--primary-color);
            }
            rect {
                stroke: var(--primary-color);
            }
            &-disabled {
                path {
                    fill: var(--disabled-color);
                }
                rect {
                    stroke: var(--disabled-color);
                }
            }
        }

        .close-button {
            rect {
                stroke: var(--primary-color);
            }

            path {
                fill: var((--primary-color));
            }
        }
    }

    // TODO: DELETE!!
    .section-general-information-reformulated {
        @extend .section-general-information;
        border-radius: 0 0 10px 10px !important;
        border-top: 1px solid transparent !important;
    }

    .section-technical-details-buttons,
    .section-technical-summary-buttons {
        right: 2.25rem !important;
        z-index: 2;

        svg {
            rect {
                stroke: var(--primary-color);
            }
            path {
                fill: var(--primary-color);
            }
        }

        .technical-summary-button-disabled {
            pointer-events: none;
            rect {
                stroke: var(--disabled-color);
            }
            path {
                fill: var(--disabled-color);
            }
        }
    }

    .section-accordion {
        .MuiAccordionSummary-root {
            align-items: center;
            min-height: 1rem;
            padding: 0;
            .MuiAccordionSummary-content {
                margin: 0;
                z-index: 1;
            }

            // .MuiIconButton-root {
            margin-right: 0 !important;

            .expand-button {
                path {
                    fill: var(--primary-color);
                }

                rect {
                    stroke: var(--primary-color);
                }
                &-open {
                    @extend .expand-button;
                    transform: rotate(180deg);
                }
            }
            // }
        }

        &::before {
            display: none;
        }

        .MuiAccordionDetails-root {
            padding: 0.625rem 0;
        }

        .principal-accordion.MuiAccordionSummary-root.Mui-expanded {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid rgba(196, 196, 196, 0.5);

            .MuiAccordionSummary-expandIcon {
                transform: rotate(0deg) !important;

                svg {
                    rect {
                        stroke: var(--primary-color);
                    }
                    path {
                        fill: var(--primary-color);
                    }
                }
            }
        }
    }

    // TITLE OF SECTION (CONTRACT OR TIMELINE)
    .process-detail-title {
        font-weight: $font-weight-semibold;
        color: var(--text-color);
        font-size: 1.25rem;
        line-height: 1.7025rem;
        padding-bottom: 0.5rem;
        margin-bottom: 0;

        @media (max-height: 767.98px) {
            padding-bottom: 0;
            margin-bottom: 0.2rem;
        }
    }

    .contract-section {
        .section-body .section-title {
            h1 {
                font-size: 1rem;
                font-style: normal;
                font-weight: $font-weight-semibold;
                line-height: 1.375rem;
                display: block;
                margin: 0;
                color: var(--primary-color);
            }
        }
        .section-body .section-title.underline {
            display: flex;
            align-items: baseline;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid rgba(196, 196, 196, 0.5);
        }

        .section-body .section-inputs {
            display: flex;
            flex-flow: row wrap;
            margin-top: 0.5rem;
        }
    }

    .techinical-details-equipments:first-of-type {
        padding-top: 0rem;
    }

    .techinical-details-equipments {
        padding-top: 0.625rem;

        .MuiAccordion-root {
            // margin-bottom: 0.8rem;
            background-color: #f5f5f5;
            box-shadow: none !important;

            &.Mui-expanded {
                margin-bottom: 0.375rem;
            }
        }

        .MuiAccordionSummary-expandIcon.Mui-expanded {
            transform: rotate(90deg);
        }

        .MuiCollapse-root {
            background-color: var(--paper-color);
        }

        .MuiAccordionSummary-root {
            flex-direction: row-reverse;
            height: 40px;
            display: flex;
            border-radius: 3px;

            .MuiAccordionSummary-expandIconWrapper {
                padding: 1rem;
            }

            .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                transform: rotate(90deg);
            }
        }

        .MuiAccordionSummary-content {
            margin-left: 1rem;
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
            line-height: 19px;
        }
    }

    .input-container {
        display: flex;
        flex-flow: column nowrap;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        line-height: 1.1875rem;

        &-button {
            &-text {
                padding: 0.125rem 0;
                color: var(--primary-color);
                height: 100%;
                text-transform: lowercase;;
                text-align: center;
                font-size: 0.875rem;
                text-decoration-line: underline;
            }
        }

        label {
            margin-bottom: 0.2rem;
            font-weight: $font-weight-semibold;
            color: $silver-chalice;
            font-size: 0.875rem;
            text-transform: uppercase;

            @media (max-height: 767.98px) {
                margin-bottom: 0;
            }
        }

        @include bootstrap.media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 0.5rem;
            margin-bottom: 1rem;
        }

        &-dropdown,
        &-textfield {
            width: 80%;
            margin-right: 1rem;
        }
    }

    .process-detail-section {
        border-radius: 10px;
        border: 1px solid $border-color;
        padding: 2rem 2.75rem;

        .technical-details-accordion {
            display: flex;
            flex-direction: column;
        }
    }

    .process-details-tabs {
        flex-wrap: nowrap;
        background-color: inherit !important;
        box-shadow: inherit !important;
        margin-bottom: 1.625rem;
        max-height: 44px;

        &-unselected {
            border-bottom: 2px solid $neutral-200;
            background-color: $neutral-100;
            color: #000000;
            box-sizing: border-box;
        }

        &-selected {
            color: var(--primary-color);
            background-color: var(--primary-color-bleached);
            box-sizing: border-box;
            border-bottom: 2px solid var(--primary-color);
        }

        .MuiTabs-flexContainer {
            justify-content: space-between;

            button {
                font-size: 0.875rem;
                text-transform: none !important;
            }
        }

        .MuiTabs-indicator {
            // make the red defaul bar invisible
            display: none !important;
            // background-color: inherit !important;
        }

        .MuiTabScrollButton-root.Mui-disabled {
            margin-left: -2.5rem;
        }

        svg {
            path {
                fill: var(--primary-color);
            }
        }
    }
}
