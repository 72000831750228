@use "../../bootstrap.scss" as bootstrap;
@use "../../global/_global-dir" as *;

.site-survey-dialog {
    .site-survey-dialog-title {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        margin-bottom: 2rem;
        background-color: rgba(245, 245, 245, 1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        position: sticky;
        top: 0;
        gap: 1rem;
        z-index: 1;

        h2 {
            font-weight: 600;
            margin-bottom: 0;
            color: var(--primary-color);
        }

        .close-button {
            margin-right: 1rem;

            svg {
                path {
                    fill: var(--primary-color);
                }
            }

            &-disabled {
                @extend .close-button;

                svg {
                    path {
                        fill: var(--disabled-color);
                    }
                }
            }
        }
    }

    .site-survey-dialog-content {
        padding: 0 3rem !important;
        overflow-y: unset !important; // remove Auto from MUI Dialog

        .site-survey {
            &-title {
                position: relative;
                margin-bottom: 1.5rem;
                h1 {
                    font-weight: 600;
                    color: var(--text-color);
                    font-size: 1.25rem;
                    line-height: 1.7025rem;
                    padding-bottom: 0;
                    margin-bottom: 0.2rem;
                }

                hr {
                    margin: 0;
                    width: 70px;
                    height: 3px;
                    background: var(--primary-color);
                    opacity: 1;
                    border-top-color: rgba(0, 0, 0, 0.1);
                }

                .details-buttons {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    gap: 1rem;

                    > div {
                        margin: 0 0.75rem;

                        &:last-of-type {
                            margin-left: 0.75rem;
                            margin-right: 0;
                        }
                    }
                    // Disabled Save Button
                    .disabled-button {
                        svg {
                            rect {
                                stroke: var(--disabled-color);
                            }
                            path {
                                fill: var(--disabled-color);
                            }
                        }
                    }
                }
            }

            &-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @media (max-width: 900px) {
                    flex-direction: column;
                }
            }

            &-details {
                flex: 0 0 21%;

                @include bootstrap.media-breakpoint-down(md) {
                    flex: 0 0 98%;
                }
            }

            &-details-upload {
                // For Upload Dialog
                flex: 0 0 46%;
                @media (max-width: 900px) {
                    flex: 0 0 98%;
                }
            }

            &-detail {
                position: relative;
                margin-bottom: 0.75rem;
                h2 {
                    color: var(--primary-color);
                    font-size: 0.875rem;
                    font-weight: $font-weight-semibold;
                }

                hr {
                    margin-top: 0.5rem;
                    margin-bottom: 0;
                }
            }

            &-input {
                display: flex;
                flex-flow: column nowrap;
                margin-bottom: 1rem;
                font-size: 0.875rem;
                font-weight: $font-weight-normal;
                line-height: 1.1875rem;

                .value {
                    width: fit-content;
                }

                label {
                    margin-bottom: 0rem;
                    font-weight: $font-weight-semibold;
                    color: $silver-chalice;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                }

                &-dropdown,
                &-textfield {
                    width: 80%;
                    margin-right: 1rem;
                }

                .MuiInputAdornment-positionEnd {
                    font-size: 0.875rem;
                    color: var(--disabled-color);
                }

                .PrivateSwitchBase-root-5 {
                    padding: 0 9px !important;
                }

                .MuiOutlinedInput-multiline {
                    padding: 12px 14px;
                }

                .MuiFormGroup-root {
                    margin-top: 0.625rem;
                }

                path {
                    fill: $neutral-400;
                }
            }

            &-input-editable {
                @extend .site-survey-input;
                // margin-bottom: 1rem;

                label {
                    color: black;
                    text-transform: none;
                    margin-bottom: 0;

                    .MuiTypography-body1 {
                        font-size: 0.875rem;
                    }

                    .MuiRadio-root {
                        padding: 0 9px !important;
                    }
                }

                .MuiOutlinedInput-root {
                    min-width: 70%;
                }
            }

            &-input-upload {
                @extend .site-survey-input;
                flex: 1 0 45%;
                max-width: 45%;
                margin-bottom: 1.5rem;

                @media (max-width: 600px) {
                    flex: 1 0 100%;
                    max-width: 100%;
                }

                label {
                    margin-bottom: 0.5rem !important;
                    color: black;
                    text-transform: none;

                    @media (max-height: 767.98px) {
                        margin-bottom: 0;
                    }
                }

                .MuiInputBase-root {
                    width: 100%;
                }

                .MuiFormGroup-root {
                    margin-top: 0rem;
                }

                &:nth-of-type(odd) {
                    margin-right: 1rem;
                }
            }

            &-divider {
                margin-left: 2rem;
                margin-right: 2rem;
                border-right: 1px dashed rgba(196, 196, 196, 0.5);
            }

            &-upload {
                display: flex;
                width: 100%;
                flex-flow: column nowrap;
                margin-bottom: 0.8rem;

                > div {
                    line-break: anywhere;
                }

                label {
                    font-size: 0.875rem;
                    font-weight: $font-weight-semibold;
                    margin-bottom: 0.5rem;
                }

                .sub-label {
                    font-size: 0.875rem;
                    font-weight: $font-weight-normal;
                    margin-bottom: 0.5rem;
                }

                .view-budget-label {
                    margin-bottom: 0.2rem;
                    font-weight: $font-weight-semibold;
                    color: $silver-chalice;
                    font-size: 0.875rem;
                    text-transform: uppercase;

                    @media (max-height: 767.98px) {
                        margin-bottom: 0;
                    }
                }

                .dropzone {
                    margin-bottom: 0;
                }

                .dropzone-content {
                    table {
                        width: 100%;
                    }
                    p {
                        margin-bottom: 0;
                    }
                }

                .MuiInputAdornment-root {
                    color: var(--disabled-color);
                }

                .docs-list {
                    font-weight: $font-weight-normal !important;

                    p {
                        margin: 0rem;
                    }
                    span {
                        font-size: 0.875rem;
                        color: var(--primary-color);
                        text-decoration: underline;
                    }

                    .filename-item {
                        padding: 0;
                        // margin-bottom: 0.5rem;
                    }

                    .filename-title {
                        margin-left: 0.5rem;
                    }

                    .icon-style path {
                        fill: var(--primary-color);
                    }

                    .MuiListItemAvatar-root {
                        min-width: auto;
                    }

                    .MuiList-padding {
                        padding: 0rem !important;
                    }
                }

                .templates-curve-load-list {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                }

                .curve-load-download {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.6rem;
                    color: var(--primary-color);
                    font-size: 0.875rem;
                    cursor: pointer;
                    svg {
                        margin-right: 0.5rem;
                    }

                    &-disabled {
                        @extend .curve-load-download;
                        color: var(--disabled-color);
                        pointer-events: none;
                        cursor: auto;
                    }

                    :hover {
                        text-decoration: underline;
                    }
                }

                .invalid-format {
                    color: red;
                    line-break: normal;
                }

                .ss-dropdown {
                    max-width: 45%;
                    margin-bottom: 1rem;

                    @media (max-width: 600px) {
                        max-width: 100%;
                    }

                    &-disabled {
                        @extend .ss-dropdown;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                }
            }

            &-curve-upload {
                @extend .site-survey-input-upload;
                flex: 0 45%;

                label {
                    margin-bottom: 0rem;
                    font-weight: $font-weight-semibold;
                    color: $silver-chalice;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                }

                .MuiIconButton-root {
                    padding: 0;
                    background-color: transparent !important;
                }
            }

            &-notEditable-upload{
                &:nth-of-type(odd){
                    margin-right: 1rem;
                }
                flex: 1 0 45%;
                max-width: 45%;
                margin-bottom: 1.5rem;
                p{
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                    color: $silver-chalice;
                    font-size: 0.875rem; 
                }
                span{
                    font-size: 0.875rem;
                }
            }
        }
    }
    .site-survey-tabs {
        flex-wrap: nowrap;
        background-color: inherit !important;
        box-shadow: inherit !important;
        margin-bottom: 1.625rem;
        max-height: 44px;

        &-unselected {
            border-bottom: 2px solid $neutral-200;
            background-color: $neutral-100;
            color: #000000;
            box-sizing: border-box;
        }

        &-selected {
            color: var(--primary-color);
            background-color: var(--primary-color-bleached);
            box-sizing: border-box;
            border-bottom: 2px solid var(--primary-color);
        }

        .MuiTabs-flexContainer {
            justify-content: space-between;

            button {
                font-size: 0.875rem;
                text-transform: none !important;
            }
        }

        .MuiTabs-indicator {
            // make the red defaul bar invisible
            display: none !important;
            // background-color: inherit !important;
        }
    }
    .site-survey-input-upload-refactor {
        @media (max-width: 600px) {
            flex: 1 0 100%;
            max-width: 100%;
        }

        label {
            margin-bottom: 0.5rem !important;
            color: black;
            text-transform: none;

            @media (max-height: 767.98px) {
                margin-bottom: 0;
            }
        }

        .MuiInputBase-root {
            width: 100%;
        }

        .MuiFormGroup-root {
            margin-top: 0rem;
        }

        &:nth-of-type(odd) {
            margin-right: 1rem;
        }
    }
    .site-survey-warning {
        display: flex;
        border-radius: 3px !important;
        font-size: 14px;
        font-weight: 400;
        padding: 0.875rem 1rem;
        align-items: center;
        font-size: 0.875rem;
        border: 1px solid var(--primary-color) !important;
        background-color: var(--primary-color-bleached) !important;
        margin-bottom: 1rem;
    }
}

.upload-documents-tab {
    .upload-info {
        margin-top: -8px; //fix to the dropzone auto margin
        display: flex;
        align-items: center;

        svg {
            path {
                fill: var(--primary-color) !important;
            }
        }

        span {
            font-size: 0.75rem;
            color: var(--primary-color);
        }
    }
}

.radio-with-label {
    display: flex;
    flex-wrap: nowrap;
}

