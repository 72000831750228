/*
Author         : Daniel Bastos && Victor Andrade
Template Name  : effizency
Version        : 1.1
*/
/*=============================================================*/

//Custom Variables
@forward "global/_global-dir";
//Custom Files
@forward "base/_base-dir";
@forward "layout/_layout-dir";
@forward "ui/_ui-dir";
@forward "pages/_pages-dir";
@forward "components/_components-dir";
@forward "@efz/_efz-dir";

//
@font-face {
    font-family: "Save2Compete";
    src: local("Save2Compete"), url("./fonts/Como_W03_Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Save2Compete-alt";
    src: local("Save2Compete"), url("./fonts/edppreonmedium.ttf") format("truetype");
}
