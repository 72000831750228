@use "../bootstrap.scss" as bootstrap;
@use "../global/_global-dir" as *;

@use "/node_modules/bootstrap/scss/vendor/rfs";

/*Base Styles*/

html {
  height: 100%;
}

:root {
  font-size: 16px;

  @include bootstrap.media-breakpoint-down(md) {
    font-size: 14px;
  }

  @media (max-height: 767.98px) {
    font-size: 15px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  z-index: 5000;
  background-color: transparent;

  @include bootstrap.media-breakpoint-down(lg) {
    height: 5px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: $gray-500;
  border-radius: 10px;
  width: 6px;
  height: 10px;

  @include bootstrap.media-breakpoint-down(lg) {
    height: 5px;
  }
}

body {
  font: {
    family: $font-family-base;
    size: $font-size-base;
    weight: $font-weight-normal;
  }

  height: 100%;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: $gray-500 transparent;

  background-color: #ffffff;
  color: #575757;

  // bootstrap 5.2.3 has different base colors
}

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important;
}

a {
  color: var(--primary-color);
  text-decoration: none;

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $headings-font-weight;
  margin: 0 0 $headings-margin-bottom;
  color: unset;
  line-height: initial;
}
// added to fix not loaded bootstrap file
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.25rem;
}
h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}
h6 {
  font-size: 0.75rem;
}

// input[type=number]::-webkit-inner-spin-button {
//   cursor: pointer;
//   width: 30px;
//   height: 30px;
// }

// Top laoding bar
#nprogress .bar {
  z-index: 5000 !important;
  background: var(--secondary-color) !important;
}

#nprogress .peg {
  @include box-shadow(0 0 10px var(--secondary-color), 0 0 5px var(--secondary-color) !important);
}

#nprogress .spinner {
  z-index: 5000 !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--secondary-color) !important;
  border-left-color: var(--secondary-color) !important;
}

// Removes the annoying black outline on buttons, menus, etc (everything with tabindex)
[tabindex] {
  outline: none !important;
  outline-style: none !important;
}

.ripple-effect {
  @extend %ripple-effect;
}

.text-transform-none {
  text-transform: none;
}

.br-break {
  word-break: break-all !important;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}

.z-index-20 {
  z-index: 20;
}

.size-120 {
  @extend %size-120;
}

.size-100 {
  @extend %size-100;
}

.size-90 {
  @extend %size-90;
}

.size-80 {
  @extend %size-80;
}

.size-70 {
  @extend %size-70;
}

.size-60 {
  @extend %size-60;
}

.size-50 {
  @extend %size-50;
}

.size-40 {
  @extend %size-40;
}

.size-30 {
  @extend %size-30;
}

.size-30 {
  @extend %size-30;
}

.size-20 {
  @extend %size-20;
}

.size-10 {
  @extend %size-10;
}

.size-8 {
  @extend %size-8;
}

.border-bottom {
  border-bottom: solid 1px bootstrap.$gray-300;
}

div[tabindex="-1"] {
  z-index: 1200 !important;
}

.joyride-overlay {
  z-index: 1510 !important;
}

.col-with-divider div[class*="col-"] {
  border-left: solid 1px bootstrap.$gray-300;

  &:first-child {
    border: 0 none;
  }
}

.height-100 {
  height: 100px !important;
}

.height-150 {
  height: 150px !important;
}

.height-200 {
  height: 200px !important;
}

.max-width-100 {
  max-width: 100px !important;
}

.max-height-120 {
  max-height: 120px !important;
}

.right-arrow {
  display: inline-block;
  color: $app-primary;
  text-transform: capitalize;

  &:after {
    content: "\f30f";
    font-family: "Material-Design-Iconic-Font";
    color: $app-primary;
    margin-left: 8px;
    font-size: 1.25rem;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.page-heading {
  @extend %jr-card-style;
  padding: 22px 26px;
  z-index: 2;

  & .title {
    text-transform: capitalize;
    font-weight: $font-weight-bold;
  }

  & .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0;
    font-size: 0.75rem;

    &-item {
      color: $light-gray;

      &.active {
        color: $app-primary;
      }
    }
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
  }
}

.sub-heading {
  font-size: 0.75rem;
  color: $sub-heading-color;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 16px !important;
  }
}

.manage-margin {
  margin-bottom: 0;

  & > * {
    margin-bottom: 6px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

// Shadow
.shadow {
  @include box-shadow($shadow);
}

.remove-table-border {
  td,
  th {
    border: 0 none;
  }
}

//Media Object list
.media-list {
  margin-bottom: 10px;

  &:not(:last-child) {
    border-bottom: solid 1px bootstrap.$gray-200;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
  }
}

.border-2 {
  border: 2px solid bootstrap.$gray-400 !important;
}

.border-w2 {
  border-width: 2px;
}

// Border Color Classes
// @each $color_name,
// $color in $colors_palette {

//   @each $color_type,
//   $color_value in $color {
//     @if $color_type=="base" {
//       .border-#{$color_name} {
//         border-color: $color_value !important;
//       }
//     }
//   }
// }

//Center Crop image

.center-crop-img {
  position: relative;
  width: 100%;
  min-height: 118px;
  overflow: hidden;

  & img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  & img.portrait {
    width: 100%;
    height: auto;
  }
}

//Bootstrap Components Override

//Buttons

a.btn-info {
  @extend %link-hover;
}

a.btn-danger {
  @extend %link-hover;
}

a.btn-warning {
  @extend %link-hover;
}

a.btn-success {
  @extend %link-hover;
}

a.btn-dark {
  @extend %link-hover;
}

.pointer {
  cursor: pointer;
}

.font-size-20 {
  font-size: 1.25rem !important;
}

//Rounded
.rounded-sm {
  @include border-radius($border-radius-sm !important);
}

.rounded-lg {
  @include border-radius($border-radius-lg !important);
}

.rounded-top-left {
  border-top-left-radius: $border-radius !important;
}

.rounded-top-right {
  border-top-right-radius: $border-radius !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: $border-radius !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: $border-radius !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

//Light Color
.text-light {
  color: lighten($black, 60%) !important;
}

.text-light-grey {
  color: $light-gray !important;
}

a.text-light {
  &:hover,
  &:focus {
    background-color: lighten($black, 50%) !important;
  }
}

//Separator
.jr-separator {
  background-color: $app-primary;
  width: $size-20;
  height: 2px;
  margin: 0 auto 10px;
}

.object-cover {
  object-fit: cover;
}

.jr-border-radius {
  @include border-radius($border-radius);
}

// Gradient Color Class
.bg-gradient-primary {
  @include gradient-directional($app-primary, lighten($app-primary, 16%), 0deg);
}

.bg-gradient-primary-x {
  @include gradient-x(darken($app-primary, 10%), lighten($app-primary, 16%), 70%, 100%);
}

.overflow-hidden {
  overflow: hidden;
}

.embed-responsive-31by9:before {
  padding-top: 28%;

  @media screen and (max-width: 575px) {
    padding-top: 36%;
  }
}

.col-half {
  @media screen and (max-width: 400px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.inputTextDisabled {
  opacity: 1;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1875em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: MuiInputBase-keyframes-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
}

.disabledCursor {
  pointer-events: none;
  cursor: default;
}

.transition1s {
  transition: all ease 1s;
}

.transition05s {
  transition: all ease 0.5s !important;
}

.transition02s {
  transition: all ease 0.2s;
}

.transition-none {
  transition: none !important;
}

// Trucates text to 1 line and makes ... appear
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flag {
  font-size: 1.3rem !important;
  height: auto !important;
  box-shadow: 0px 0px 0px 1px var(--divider-color);
}

//Removes presets of widht and height
.unset-size {
  min-height: unset !important;
  min-width: unset !important;
  height: unset !important;
  width: unset !important;
}

//Size according to screen
.mh-vh-10 {
  min-height: 10vh;
}
.mh-vh-15 {
  min-height: 15vh;
}
.mh-vh-25 {
  min-height: 25vh;
}
.mh-vh-50 {
  min-height: 50vh;
}
.mh-vh-75 {
  min-height: 75vh;
}
.mh-vh-90 {
  min-height: 90vh;
}
.mw-vw-10 {
  min-width: 10vw;
}
.mw-vw-15 {
  min-width: 15vw;
}
.mw-vw-25 {
  min-width: 25vw;
}
.mw-vw-50 {
  min-width: 50vw;
}
.mh-vw-75 {
  min-width: 75vw;
}
.mh-vw-90 {
  min-width: 90vw;
}
.w-45 {
  width: 45%;
}

//removing margins
.ml-r15 {
  margin-left: -15px;
}

// added to fix not loaded bootstrap file
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

b,
strong {
  font-weight: 800;
}
