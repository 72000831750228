@use "../global/_global-dir" as *;

.efz-button {
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--primary-color);
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--primary-color);
    font-size: 0.875rem;
    text-transform: uppercase;

    display: flex;
    align-items: center;

    &.small {
        background-color: white;
        padding: 6px;

        svg {
            margin: 0;
        }
    }

    &:hover {
        background-color: var(--primary-color-bleached);
    }

    &:hover > .icon-update path {
        fill: var(--primary-color-bleached);
    }

    .icon-update {
        path {
            transition: fill 0.3s;
            fill: white;
            stroke: var(--primary-color);
            stroke-width: 1.2;
        }

        path:nth-child(2) {
            fill: var(--primary-color);
            stroke-width: 0.2;
        }
        path:last-child {
            fill: var(--primary-color);
            stroke-width: 0.2;
        }
    }

    svg {
        margin-right: 0.5rem;

        path {
            fill: var(--primary-color);
        }
    }

    &-disabled {
        @extend .efz-button;
        background-color: $disabled-light;
        color: var(--disabled-color);
        border: unset;
        pointer-events: none;
        box-shadow: unset;
        .icon-update {
            path {
                transition: fill 0.3s;
                fill: rgb(219, 215, 215); //this colors doesnt clash with the opacity of disable
                stroke: var(--disabled-color);
                stroke-width: 1.2;
            }

            path:nth-child(2) {
                fill: var(--disabled-color);
                stroke-width: 0.2;
            }
            path:last-child {
                fill: var(--disabled-color);
                stroke-width: 0.2;
            }
        }
        svg {
            path {
                fill: var(--disabled-color);
            }
        }
    }
}
