@use "../global/_global-dir" as *;

.efz-back-button {
    // padding: 0.5rem 0;
    margin-bottom: 1.5rem !important;
    text-decoration: none !important;
    &-label {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 19px;
        color: $neutral-800;
        margin-left: 0.5rem;

    }
}
