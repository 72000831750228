@use "../global/_global-dir" as *;

.reformulate-comparison-dialog {
    .MuiPaper-root {
        min-width: fit-content !important;
        position: relative;
        padding: 2rem 3.5rem 2.75rem 3.5rem;

        @media (max-width: 600px) {
            min-width: 100% !important;
        }
    }

    .MuiDialog-paperScrollPaper {
        @media (max-width: 600px) {
            max-height: 100% !important;
        }
    }

    &-header {
        label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: fit-content;

            span {
                font-size: 1rem;
                font-weight: $font-weight-semibold;
                line-height: 22px;
            }
            hr {
                margin: 0;
                width: 70px;
                height: 3px;
                background: var(--primary-color);
                margin-bottom: 1.75rem;
                border-radius: 3px;
            }
        }
        .close-icon {
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            margin: 1.5rem;

            svg {
                path {
                    fill: rgba(229, 229, 229, 0.8);
                }
            }

            &:hover {
                background-color: transparent;
                svg {
                    path {
                        fill: rgba(229, 229, 229, 1);
                    }
                }
            }
        }
    }

    &-content {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .MuiAccordion-root {
        padding: 0 !important;
        background-color: #f5f5f5;
        box-shadow: none !important;

        &.Mui-expanded {
            margin-bottom: 0.375rem;
        }
    }

    .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(90deg);
    }

    .MuiCollapse-root {
        background-color: var(--paper-color);
    }

    .MuiAccordionSummary-root {
        flex-direction: row-reverse;
        height: 40px;
        display: flex;
        border-radius: 3px;
        padding: 0;

        .MuiIconButton-root {
            padding: unset;
            margin-right: 1rem !important;
        }

        .MuiAccordionSummary-content {
            font-weight: $font-weight-semibold;
        }

        .MuiAccordionSummary-expandIconWrapper {
            padding: 1rem;
        }
    }
}
