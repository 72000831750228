@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;
/*Dropzone Styles*/
.dropzone {
    &-card {
        position: relative;
    }

    &-uploaded {
        margin-bottom: 1rem;
        div {
            margin-bottom: 0.5rem;
            display: flex;
            .filename {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                text-decoration-line: underline;
                color: var(--primary-color);
            }

            .spacing {
                height: 1rem;
            }

            .delete {
                display: flex;
                .MuiIconButton-root {
                    margin: 0 0 0 10px;
                    svg {
                        path {
                            fill: var(--primary-color);
                        }
                    }
                }
            }
        }
    }

    margin-bottom: 0.75rem;

    &-file-btn {
        border: 1px dashed bootstrap.$gray-600;
        margin: 0 auto;
        padding: 22px;
        background: #f5f5f5;
        @include display-flex(flex, column, nowrap);
        @include justify-content(center);
        @include border-radius(4px);
        cursor: pointer;

        & :last-child {
            margin-bottom: 0;
        }

        p {
            text-align: center;
            font-size: 0.75rem;
        }

        b {
            color: #3366bb;
            font-weight: $font-weight-normal;
        }
    }

    & .btn {
        margin-top: 20px;
    }
}
