@use "../../global/_global-dir" as *;
@use "../../bootstrap.scss" as bootstrap;

.timeline-dialog {
    .margin-double {
        width: 50%;
        margin-right: calc(50%);
    }

    .input-container {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        font-weight: $font-weight-normal;
        line-height: 1.1875rem;

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiSelect-select {
            color: #c8c8c8;

            span {
                color: #202020;
            }
        }

        .input-error {
            margin-top: 0.25rem;
            color: $error-600;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;

            svg {
                path {
                    fill: $error-600;
                }
            }
        }

        label {
            font-family: "Open Sans";
            font-weight: 500;
            color: $neutral-800;
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
        }

        @include bootstrap.media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 0.5rem;
            margin-bottom: 1rem;
        }

        &-dropdown,
        &-textfield {
            width: 80%;
            margin-right: 1rem;
        }

        .MuiInputBase-root {
            width: 100%;
        }
    }

    .horizontal-line {
        height: 1px;
        background-color: var(--primary-color);
        width: calc(100% - 6.5rem);
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        margin-left: 3.5rem;
        border: none;
        opacity: 1;
    }

    .MuiDialogTitle-root {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        color: var(--primary-color);
        padding-left: 3.5rem;
        padding-top: 2rem;
        padding-bottom: 0;
    }

    .MuiIconButton-root {
        padding: 0;
        margin-top: -0.5rem;
        margin-left: 2rem;

        .close-icon {
            path {
                fill: var(--primary-color);
            }
        }
    }

    .MuiDialogContent-root {
        padding: 0;
    }

    &-confirmation {
        padding: 0 3.5rem;
        &-content {
            text-align: center;
            margin-bottom: 1.5rem;
        }
    }

    &-contract {
        padding: 0 3.5rem !important;

        .MuiTabPanel-root {
            padding: 0 0 2.625rem 0;
        }

        section {
            display: flex;
            gap: 12rem;

            div {
                .title {
                    color: $neutral-600;
                    font-family: Open Sans;
                    font-size: 0.875rem;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                .text {
                    color: $neutral-800;
                    font-family: Open Sans;
                    font-size: 0.875rem;
                    font-weight: 400;
                }
            }
        }

        .table {
            margin-top: 1.5rem;

            .thead {
                display: flex;
                justify-content: space-around;
                border-radius: 0.3125rem;
                border: 0.0625rem solid $neutral-400;
                background: $neutral-200;
                text-align: center;
                text-transform: capitalize;


                .th {
                    color: $neutral-800;
                    font-family: Open Sans;
                    font-size: 0.875rem;
                    font-weight: 600;
                    padding: 0.5rem 0;
                }

            }

            .trow {
                display: flex;
                justify-content: space-around;
                border-radius: 0.3125rem;
                margin-top: 0.5rem;
                padding: 1.1563rem 0;

                color: $neutral-800;
                font-family: Open Sans;
                font-size: 0.875rem;
                font-weight: 400;

                &.selected {
                    border: 1px solid var(--primary-color);
                    background: var(--primary-color-bleached);
                }

                &.unselected {
                    border: 1px solid $neutral-200;
                    background: white;
                }
            }
        }
    }

    &-form {
        padding: 0 3.5rem !important;

        .vertical-line {
            background-color: #c4c4c4;
            width: 2px;
            margin: 0 2rem;
        }

        .structure-section {
            width: 45%;
            &-label {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 0.875rem;
                color: $neutral-600;
            }
            &-material,
            &-disposition,
            &-location {
                display: flex;
            }
            &-title {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: $font-weight-semibold;
                font-size: 0.875rem;
                color: $neutral-600;
                margin-bottom: 1.5rem;
            }
            label {
                font-family: "Open Sans";
                font-weight: 500;
                color: #202020;
                font-size: 0.875rem;
            }
        }

        &-input {
            &-label {
                label {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.875rem;
                    color: $neutral-800;
                    margin-bottom: 0.5rem;
                }
            }
            &-label-2 {
                label {
                    font-family: "Open Sans";
                    font-size: 0.875rem;
                    font-weight: 500;
                    color: $neutral-600;
                    margin-right: 10.5rem;
                }
            }
            &-content {
                &.send-form {
                    display: flex;
                }
                margin-bottom: 1.5rem;
                .MuiFormControl-root {
                    width: 100%;
                }
                p {
                    margin: 0;
                    font-family: "Open Sans";
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: $neutral-800;
                }
            }
        }
    }

    .MuiPaper-root {
        width: 100%;
        max-width: 736px;
    }
}
