@use "../global/_global-dir" as *;

.min-button {
    max-height: 1.375rem;
    padding: 0.25rem 0.5rem;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--paper-color);
    transition: background-color 0.2s;
    white-space: nowrap;

    // Font
    font-weight: $font-weight-normal;
    font-size: 0.625rem;

    &:hover {
        background-color: var(--primary-color-bleached);
    }
}
