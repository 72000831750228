@use "../global/_global-dir" as *;

/* Components - Tooltip - genericStyles
 ========================================================================== */
.tooltip-gc {
    margin-left: 5px;
    cursor: help;

    svg {
        vertical-align: unset;
    }

    h3 {
        margin: 0;
    }
}

.popover {
    padding: 0.5rem;
}

div.MuiTooltip-tooltip {
    background-color: var(--paper-color);
    border: 0.094rem solid $silver-chalice;
    color: var(--text-color);
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    padding: 0.5rem 1rem;
    text-transform: none;
    pointer-events: auto;
    z-index: 10000;
}

.custom-tooltip {
    z-index: 10000;
}

.MuiTooltip-arrow {
    &::before {
        color: var(--paper-color);
        border: 0.094rem solid $silver-chalice;
    }
}

.tooltip-info {
    display: flex;
    div.MuiTooltip-tooltip {
        @extend .MuiTooltip-tooltip;
        background-color: $info-50 !important;
        border: 1px solid $info-600 !important;
    }

    .MuiTooltip-arrow {
        &::before {
            background-color: $info-50;
            border: 1px solid $info-600;
        }
    }

    svg {
        path {
            fill: $info-600;
        }
    }
}

.tooltip-error {
    div.MuiTooltip-tooltip {
        @extend .MuiTooltip-tooltip;
        background-color: $error-50 !important;
        border: 1px solid $error-600 !important;
    }

    .MuiTooltip-arrow {
        &::before {
            background-color: $error-50;
            border: 1px solid $error-600;
        }
    }

    svg {
        path {
            fill: $error-600;
        }
    }
}

.tooltip-content-width {
    div.MuiTooltip-tooltip {
        min-width: 200px;
    }
}

.tooltip-cursor-help {
    cursor: help !important;
}
