// * Redefine password dialog that is prompted when user logs in for first time after activating account * \\

@use "../global/_global-dir" as *;

.redefine-password-dialog {
    // width override
    .MuiDialog-paperWidthSm {
        max-width: fit-content;
    }

    .dialog-title {
        padding: 2rem 3.5rem 0 3.5rem;
        margin-top: 0;
        font-size: 1rem;
        color: var(--text-color);
        font-weight: $font-weight-semibold !important;
        text-align: left;

        hr {
            margin: 0;
            margin-top: 0.5rem;
            width: 70px;
            border: 2px solid var(--primary-color);
            opacity: 1;
        }
    }

    .dialog-body {
        display: flex;
        flex-direction: row;
        column-gap: 5rem;
        padding: 1.5rem 3.5rem 2rem 3.5rem;
        margin-bottom: 0;

        hr {
            border-top: 1px dashed #c4c4c4;
            margin: 1.5rem 0;
        }

        .dialog-bullets {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            text-align: left;

            .dialog-bullet {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                column-gap: 0.5rem;
                font-size: 0.875rem;
                font-weight: $font-weight-normal;

                .bullet-icon {
                    width: 15px;

                    svg {
                        width: 15px;
                    }
                }

                &-success {
                    text-decoration: line-through;
                }
            }
        }
    }

    .dialog-buttons {
        padding: 0 3.5rem 2.5rem 3.5rem;
        display: flex;
        justify-content: center;
    }

    .input-container {
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        margin-bottom: 1.5rem;
        width: 300px;

        label {
            margin-bottom: 0.5rem;
            font-weight: $font-weight-bold;
            color: var(--text-color);
            font-size: 0.875rem;
            flex: 1 1;
            text-align: left;
        }
    }

    .info-text {
        display: grid;
        align-items: flex-start;
        text-align: center;
        padding: 1rem;
        min-width: fit-content;
        height: fit-content;

        .MuiAlert-icon {
            padding: 0;
            font-size: unset;
            width: fit-content;
            position: absolute;
        }
    }

    .MuiAlert-message {
        padding: 0 !important;
    }
}
