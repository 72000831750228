@use "../global/_global-dir" as *;

.multi-autocomplete {
    position: relative;
    margin-bottom: 1rem;
    cursor: pointer !important;
    label {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    .multiline-autocomplete {
        cursor: pointer !important;
        min-height: 40px;

        background-color: white;

        .MuiOutlinedInput-root {
            height: fit-content !important;
        }
    }
}

.selected-chips {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.selected-chip {
    border-radius: 3px !important;
    margin-right: 0.5rem !important;
    margin-top: 0.375rem !important;
    height: 25px !important;
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color-bleached) !important;
    color: var(--primary-color) !important;

    .MuiChip-label {
        // Text of the choice
        padding-right: 0.375rem;
        padding-left: 0.5rem;
        font-size: 0.625rem;
    }

    .MuiIconButton-label {
        width: 8px;
    }

    .MuiIconButton-root {
        padding: 0.5rem;
    }

    .MuiChip-deleteIcon {
        // delete X on choice
        margin: 0 0 0 -6px;

        svg {
            path {
                fill: var(--primary-color);
            }
        }
    }
}

.selected-chip:first-of-type {
    margin-top: 0 !important;
}

.loading-SM {
    // replace the position of the loading for the endAddornement
    min-width: 0 !important;
    left: 90% !important;
}

.popper-auto {
    height: 0;
}

.testsss {
    border: 1px solid red;
    width: 15px;
    height: 20px;
}
