@use "../global/_global-dir" as *;
@use "../bootstrap.scss" as bootstrap;

/*Header Styles*/

.app-header {
  @include display-flex(flex, column, nowrap);
  flex-shrink: 0;
  position: sticky;
  top: 0;
  left: auto;
  right: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  min-height: 70px;
  max-height: 70px;
  background-color: var(--paper-color);
  padding: 0 1.5rem;
  z-index: 1200;

  .dropdown-menu {
    width: 22rem;
    border-radius: 0;
    padding: 0;
    background-color: var(--paper-color);
    margin: 0;
    border-top: none !important;
    cursor: default;
    box-shadow: inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; // create shadow above dropdown menu

    @include bootstrap.media-breakpoint-down(md) {
      width: 100%;
      border-left: none !important;
      border-right: none !important;
      //transition: all ease 0.5s;
      left: -5px !important;
    }
  }

  > .content-wrapper {
    height: 70px;
  }
}

.dropdown-header-section {
  @include display-flex(flex, row, nowrap);
  padding: 1rem;
  align-items: center;

  .header-section-text {
    @include display-flex(flex, column, nowrap);

    h5 {
      color: var(--text-color);
    }

    h6 {
      color: var(--text-color-secondary);
    }
  }

  svg path {
    fill: var(--primary-color);
  }

  button {
    display: flex;
    flex-grow: 1;
    min-width: 30px;
    max-width: 45px;
    max-height: 50px;
    padding: 10px 5px;
    margin-left: 0.5rem;
    font-size: 1.5rem;
  }

  &.clickable {
    cursor: pointer;
  }

  &:hover {
    .facility-arrow {
      background-color: var(--paper-color) !important;

      i {
        color: var(--primary-color) !important;
      }
    }
  }
}

// Buttons section on bottom part of header dropdowns
.dropdown-buttons-section {
  @include display-flex(flex, row, nowrap);
  padding: 0.5rem;
  justify-content: space-between;
  border-top: 1px solid var(--divider-color);

  .logout-button {
    height: 40px;
    font-size: 0.9rem;
    padding: 12px 15px;
    font-weight: $font-weight-normal;
    border: none;
    background-color: unset;

    &:hover {
      color: var(--primary-color);
    }
  }
}

.header {
  &-efz-logo {
    margin-left: 2rem;
    max-height: 20px;
    height: 100%;
  }
}

.header-logo {
  display: flex;

  a,
  span {
    @include display-flex(flex, row, nowrap);
    text-decoration: none;
    color: var(--text-color);
    align-items: center;

    img {
      height: 40px;
    }

    // svg {
    //   max-width: 9.375rem;

    //   @media (max-width: 575.98px), (max-height: 767.98px) {
    //     max-width: 4rem;
    //   }
    // }

    span {
      align-self: center;

      @include bootstrap.media-breakpoint-down(md) {
        display: none;
      }

      p {
        margin: 0;
        font-size: 1.5rem;
        font-weight: $font-weight-light;

        @include bootstrap.media-breakpoint-down(xl) {
          font-size: 1rem;
        }
      }
    }
  }
}

.user-card {
  align-items: unset;

  .tertiary-button {
    margin: 0 !important;
    text-transform: uppercase;

    &:hover {
      color: var(--primary-color);
    }
  }
}

.header-menu {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

ul.menu-list {
  @include display-flex(flex, row, nowrap);
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;

  & li.menu-item {
    display: flex;
    align-items: center;
    margin: 0px;
    list-style: none;

    &-expanded {
      @extend .menu-item;
      background-color: var(--background-color);

      .menu-item-text h6 {
        line-height: 0;
      }
    }

    &:last-child .menu-item-content {
      border: none;
    }

    &:hover,
    &:focus {
      background-color: var(--background-color);
      cursor: pointer;

      & span svg:not(.main-header-flag) path {
        fill: var(--primary-color);
      }

      .menu-item-text h5 {
        color: var(--primary-color);
      }
    }

    .dropdown {
      display: flex;
      height: 100%;
    }
  }
}

.header-dropdown-toggle {
  padding: 1rem 0;

  @media (max-width: 575.98px), (max-height: 767.98px) {
    padding: 0.5rem 0;
  }
}

// Displays the icon and text
.menu-item-content {
  @include display-flex(flex, row, nowrap);
  height: 100%;
  padding: 0 1rem;
  align-items: center;
  border-right: 1px solid var(--divider-color);

  // &.item-large {
  //   min-width: 250px;

  //   @include bootstrap.media-breakpoint-down(lg) {
  //     min-width: 0 !important;
  //   }
  // }

  & > span {
    align-self: center;
  }

  & h5 {
    color: var(--text-color);
    margin: 0;
    font-weight: $font-weight-normal;
    align-self: center;
  }

  &-last {
    @extend .menu-item-content;
    border-right: unset;
  }

  // user avatar
  & .user-item {
    width: 35px;
    height: 35px;

    h5 {
      margin: 0;
      color: var(--paper-color);
    }
  }
}

// user icon
.header-user-icon {
  width: 25px;
  height: auto;
  fill: var(--primary-color);
}

// Text of each dropdown menu item displayed after icon
.menu-item-text {
  display: flex;
  flex-flow: column nowrap;

  @include bootstrap.media-breakpoint-down(lg) {
    & > h5 {
      display: none;
    }

    & > h6 {
      display: none;
    }
  }

  .menu-item-label {
    color: var(--text-color);
    margin: 0;
    font-weight: $font-weight-normal;
    align-self: flex-start;

    &--open {
      @extend .menu-item-label;
      color: var(--primary-color);
    }
    &--disabled {
      @extend .menu-item-label;
      cursor: default;
      color: var(--disabled-color-icon);
    }
  }

  & > h6 {
    // display: inline;
    color: var(--text-color-secondary);
    margin: 0;
    font-weight: $font-weight-normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 9rem;
  }

  &:hover {
    color: var(--paper-color) !important;
  }
}

// Arrow of each menu item displayed after icon
.menu-item-arrow {
  color: $silver-light;
  margin-left: 0.5rem;
  margin-right: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 1.2rem;
    transition: all linear 0.2s;
  }

  &--closed {
    @extend .menu-item-arrow;
  }

  &--open {
    @extend .menu-item-arrow;

    i {
      transform: rotate(180deg);
    }
  }
}

// Generic header action button (inside dropdown)
button.facility-arrow {
  border-radius: 50%;
  background-color: var(--primary-color);
  min-width: 25px;
  max-width: 25px;
  max-height: 25px;
  font-size: 1.25rem;
  padding: 0;

  i {
    color: var(--paper-color);
  }

  &:hover {
    i {
      color: var(--primary-color) !important;
    }
  }
}

// Container for the facility dropdown buttons
.facility-dropdown-buttons {
  @include display-flex(flex, row, nowrap);
  flex-grow: 1;
  justify-content: flex-end;

  button {
    height: 40px;
    font-size: 0.9rem;
    padding: 12px 15px;
    font-weight: $font-weight-normal;
    border: none;
    background-color: unset;

    &:hover {
      color: var(--primary-color);
    }
  }
}

// languages dropdown
// languages dropdown
.language {
  &-icon {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: $font-weight-semibold;
    line-height: 1.05rem;
  }
  &-italic {
    font-style: Italic;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.313rem;
    margin-top: 0.125rem;
  }
  &-list {
    li {
      padding: 0rem 1rem;

      & > div {
        padding: 0.75rem 0rem;
        border-bottom: 1px solid var(--divider-color);
        line-height: 1.313rem;
      }
      &:first-child {
        padding-top: 0.25rem;
      }
    }

    li:last-child {
      padding-bottom: 0.25rem;

      & > div {
        border-bottom-color: transparent;
      }
    }

    li:hover {
      background-color: var(--primary-color);

      h5,
      .language-icon,
      .language-italic,
      span {
        color: var(--paper-color) !important;
      }

      .default-svg--open svg path {
        fill: var(--paper-color);
      }
    }
    li.active {
      color: var(--primary-color);

      h5 {
        color: var(--primary-color);
      }
    }

    h5 {
      color: var(--text-color);
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      line-height: 1.313rem;
    }
    .language-icon {
      line-height: 1.313rem;
    }
  }
}

// the flag that appears in the header
.main-header-flag {
  font-size: 1.5rem !important;
  height: auto !important;
  box-shadow: 0px 0px 0px 1px var(--divider-color);
}

.dark-mode-icon {
  font-size: 1.3rem;
  color: var(--primary-color);
}

// Autocomplete Header \\
.autocomplete-header {
  margin-top: $top-margin-question;

  .question-text {
    display: none;
  }

  .MuiAutocomplete-root {
    padding: 0.5rem !important;
    border-top: 1px solid var(--divider-color);
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInputBase-root {
    padding-right: 10px;

    i.zmdi.zmdi-search {
      font-size: 1.5rem;
    }
  }

  .MuiAutocomplete-popper {
    width: 100% !important;
    position: inherit !important;
    border-top: 1px solid var(--divider-color);

    .MuiAutocomplete-paper {
      margin: 0 !important;
    }

    .MuiAutocomplete-listbox {
      overflow-x: hidden !important;
      padding: 0;
    }
  }

  .MuiAutocomplete-option {
    padding: 0.75rem 1rem;
    color: initial;
    border-bottom: 1px solid var(--divider-color);

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: var(--primary-color);

      h5,
      h6 {
        color: var(--paper-color) !important;
      }
    }

    span {
      color: var(--text-color);
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap;
      font-weight: $font-weight-normal;
    }
  }

  .autocomplete-header-option-wrapper {
    display: flex;
    flex-flow: column nowrap;
    max-width: 10rem;

    @include bootstrap.media-breakpoint-down(lg) {
      max-width: 100%;
    }

    & > h5 {
      color: var(--text-color);
      margin: 0;
      font-weight: 400;
      align-self: flex-start;
    }

    & > h6 {
      color: var(--text-color-secondary);
      margin: 0;
      font-weight: 400;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      color: var(--paper-color) !important;
    }
  }

  .autocomplete-loader {
    flex: unset;
    min-width: 0;
  }
}

// END Autocomplete Header \\
