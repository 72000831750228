@use "../global/_global-dir" as *;

.warning-container {
    width: 100%;
    display: flex;

    .warning-icon {
        display: flex;
        align-items: flex-start;
        svg {
            min-width: 20px;
            min-height: 20px;
        }
    }
    .warning-green {
        @extend .warning-icon;
        padding: 1rem;
        background-color: $success-50;
        border: 1px solid $success-600;
        border-radius: 3px;
    }

    .warning-red {
        @extend .warning-icon;
        padding: 1rem;
        background-color: $edp-red-50;
        border: 1px solid $edp-red-400;
        border-radius: 3px;
    }
}
