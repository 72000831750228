.efz-drawer {
    position: fixed;
    inset: 0;
    z-index: 900;
    pointer-events: none;

    .MuiDrawer-paper {
        width: 100%;
        height: 100%;
        pointer-events: all;
        right: 0px;
    }

    & > .MuiDrawer-paper {
        height: fit-content;
    }

    .drawer-collapse-toggle {
        z-index: 1100;

        .MuiDrawer-paperAnchorTop,
        .MuiDrawer-paperAnchorBottom {
            width: 267px;
            height: 43px;
            // margin-left: calc(50% - 133.5px); // half of button width
        }

        .MuiDrawer-paperAnchorRight,
        .MuiDrawer-paperAnchorLeft {
            width: 43px;
            height: 267px;
            // margin-top: calc(25% - 133.5px); // half of button height
        }

        .MuiDrawer-paperAnchorTop {
            top: 0;

            svg {
                transform: rotateZ(-90deg);
            }
        }

        .MuiDrawer-paperAnchorBottom {
            bottom: 0;

            svg {
                transform: rotateZ(90deg);
            }
        }

        .MuiDrawer-paperAnchorRight {
            right: 0;

            svg {
                transform: none;
            }
        }

        .MuiDrawer-paperAnchorLeft {
            left: 0;

            svg {
                transform: scaleX(-1);
            }
        }

        .MuiDrawer-paper {
            background-color: transparent;
            border: 1px solid #e5e5e5;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            .drawer-collapse-toggle-icon {
                z-index: 1;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                padding: 0 14px 0 12px;
                margin: 0;
                background-color: #fff;

                svg {
                    margin: 0;
                }
            }
        }

        &-icon {
            width: 100%;
            justify-content: flex-start;
            background-color: #f5f5f5;
            border-radius: unset;
            margin-right: auto;
            padding: 0.8175rem 1.1875rem 0.9375rem 1.1875rem;

            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.3125rem;
            color: #202020;

            &:hover {
                background-color: #f5f5f5;
            }

            svg {
                width: 17px;
                height: 19px;
                margin-right: 1.1875rem;

                path {
                    stroke: var(--primary-color);
                }
            }
        }
    }

    .drawer-content {
        .MuiDrawer-paperAnchorTop,
        .MuiDrawer-paperAnchorBottom {
            margin: 0 auto;
        }

        .MuiDrawer-paperAnchorRight,
        .MuiDrawer-paperAnchorLeft {
            margin: auto 0;
        }

        .MuiDrawer-paper {
            border: 1px solid #e5e5e5;
            border-radius: 6px;
            overflow: hidden;
            @media (max-width: 992px) {
                max-width: 100% !important;
                border-radius: unset;
            }
            @media (min-width: 993px) and (max-width: 1200px) {
                max-width: 50% !important;
            }
        }

        &-inner {
            padding: 0.75rem 0.6875rem;
            overflow-y: scroll;
        }
    }
}
