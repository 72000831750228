.default-svg {
  svg {
    path {
      fill: #202020;
    }
  }
  &--disabled {
    svg {
      path {
        fill: var(--disabled-color-icon);
      }
    }
  }
  &--open {
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
}

.default-svg-base {    
  svg {
    path {
      fill: var(--primary-color);
    }
  }
}

.with-stroke {
  path {
    stroke: var(--primary-color);
  }
}